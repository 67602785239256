import { WordListMappingType } from "../types";

export const TraumaPeopleWordListMap: WordListMappingType = {
  "Trauma People": {
    "Issues": {
      "Boundaries": [
        "<b>Boundary Evasion</b>: An evasion is when someone ignores us even if we ask for something or need something. It could be when we go to hug someone and they do not hug back. It is a purposeful action of evading someone. If a child experiences boundary on a regular basis they will feel shame.",
        "<b>Boundary Invasion</b>: This is when someone comes into our space on any of the above mentioned boundary areas without regard for us. When they plow in so to speak, being unaware or not caring about limits we may have set.",
        "<b>Enmeshed Boundaries</b>: This is when we are overly connected to someone else and do not have a clear sense of our own selves. How this will look is that if the person we are enmeshed with is having a bad day then we have to join them and cannot be where we truly are in our own experience and life. Sometimes families and groups will do this as a whole. We need to have our own boundaries and expect that others have their own too.",
        "<b>Honored/Not honored</b>: This refers to either the selection that my boundaries are being honored or I am honoring someone else's boundaries or they are not being honored or respected.",
        "<b>Intellectual Boundary</b>: Each person has an unique capacity for knowledge and understanding, and the right to apply these abilities in his / her own way. Within the limits of the law and ethics, we all have the right to think and do what we want for ourselves, as well as accept the consequences of those choices. Sometimes, we want to be alone with our thoughts in order to clarify them. Intellectual boundaries allow us to do that, and the freedom to choose what we want to think.",
        "<b>Mental Boundary</b>: This is a boundary around our thoughts and ideas. We do not have to share everything that we are thinking with everyone. We get to decide what thoughts we share and with who. Just because someone may ask what we are thinking, we do not have to share unless we decide to. Our thoughts are our own.",
        "<b>Physical Boundary</b>: This has to do with the actual physical space around our bodies. We all have a different comfort space that we are comfortable with and can tolerate. There is no set distance for everyone. We do not have to allow people to come in our personal space if we do not want to. We should also ask permission to go into someone else's personal space and not just assume because we are okay with it that they are.",
        "<b>Learn about boundaries</b>: This is an important topic area for everyone. If you do not know about boundaries get a book and read up on what they are and how to use them. Boundaries are how we teach others how we want to be treated!!!!",
        "<b>Spiritual Boundary</b>: This is how we protect and share our spiritual beliefs. By safe guarding and honoring our spiritual boundaries it helps us define our spiritual beliefs with others and allows for differences to be acknowledged, respected and honored. It says that we can be different and still have a relationship of some sort and not have to think a like.",
        "<b>Rigid Boundaries</b>: This is when we have walls up and do not allow anyone to interact with us or come into our lives to any great degree. Rigid boundaries are often used when we feel we need to protect ourselves for some reason or if we do not trust.",
        "<b>Weak Boundaries</b>: This is when someone sets boundary but does not back it up and therefore it will probably not feel or come across as a boundary. It is very hard to take a weak boundary seriously.",
        "<b>Wavering Boundaries</b>: This is when someone is wishy washy about the boundaries they set which sends out a very unclear mixed message. They may set one and not the other or they may set a boundary and not maintain it and not honor it themselves. It sends a very strong message that you do not expect to be honored and respected.",
        "<b>Set a boundary</b>: This is telling you that you need to set a boundary somewhere in your life with someone or maybe even with yourself.",
      ],
    },

    "Emotional": {
      "Scared": [
        "Afraid",
        "Alarmed",
        "Anxious",
        "Appalled",
        "Apprehensive",
        "Awed",
        "Concerned",
        "Defensive",
        "Desperate",
        "Doubtful",
        "Fearful",
        "Frantic",
        "Full of dread",
        "Guarded",
        "Horrified",
        "Impatient",
        "Insecure",
        "Intimidated",
        "Nervous",
        "Overwhelmed",
        "Panicky",
        "Perplexed",
        "Petrified",
        "Reluctant",
        "Shaken",
        "Shocked",
        "Shy",
        "Skeptical",
        "Startled",
        "Stunned",
        "Suspicious",
        "Swamped",
        "Tense",
        "Terrified",
        "Threatened",
        "Timid",
        "Tormented",
        "Uneasy",
        "Unsure",
        "Vulnerable"
      ],
      "Confused": [
        "Ambivalent",
        "Awkward",
        "Baffled",
        "Bewildered",
        "Bothered",
        "Constricted",
        "Directionless",
        "Disorganized",
        "Distracted",
        "Doubtful",
        "Flustered",
        "Foggy",
        "Hesitant",
        "Immobilized",
        "Misunderstood",
        "Perplexed",
        "Puzzled",
        "Stagnant",
        "Surprised",
        "Torn",
        "Trapped",
        "Troubled",
        "Uncertain",
        "Uncomfortable",
        "Undecided",
        "Unsettled",
        "Unsure"
      ],
      "Happy": [
        "Admired",
        "Alive",
        "Appreciated",
        "Assured",
        "Cheerful",
        "Confident",
        "Content",
        "Delighted",
        "Determined",
        "Ecstatic",
        "Elated",
        "Encouraged",
        "Energized",
        "Enthusiastic",
        "Excited",
        "Exuberant",
        "Flattered",
        "Fortunate",
        "Fulfilled",
        "Glad",
        "Good",
        "Grateful",
        "Gratified",
        "Hopeful",
        "Joyful",
        "Jubilant",
        "Justified",
        "Loved",
        "Marvelous",
        "Optimistic",
        "Peaceful",
        "Pleased",
        "Proud",
        "Relaxed",
        "Relieved",
        "Resolved",
        "Respected",
        "Satisfied",
        "Terrific",
        "Thrilled",
        "Tranquil",
        "Valued"
      ],
      "Sad": [
        "Alienated",
        "Ashamed",
        "Burdened",
        "Condemned",
        "Crushed",
        "Defeated",
        "Dejected",
        "Demoralized",
        "Depressed",
        "Deserted",
        "Despised",
        "Devastated",
        "Disappointed",
        "Discarded",
        "Discouraged",
        "Disgraced",
        "Disheartened",
        "Disillusioned",
        "Dismal",
        "Distant",
        "Distraught",
        "Distressed",
        "Drained",
        "Empty",
        "Exhausted",
        "Grievous",
        "Helpless",
        "Hopeless",
        "Humbled",
        "Inadequate",
        "Islanded",
        "Isolated",
        "Lonely",
        "Miserable",
        "Mournful",
        "Neglected",
        "Pitiful",
        "Regretful",
        "Rejected",
        "Resigned",
        "Sorrowful",
        "Terrible",
        "Unappreciated",
        "Uncared for",
        "Unloved",
        "Unwanted",
        "Upset",
        "Worthless",
        "Wounded"
      ],
      "Angry": [
        "Abused",
        "Aggravated",
        "Agitated",
        "Anguished",
        "Annoyed",
        "Betrayed",
        "Cheated",
        "Coerced",
        "Controlled",
        "Deceived",
        "Disgusted",
        "Dismayed",
        "Displeased",
        "Dominated",
        "Enraged",
        "Exasperated",
        "Exploited",
        "Frustrated",
        "Fuming",
        "Furious",
        "Harassed",
        "Hateful",
        "Hostile",
        "Humiliated",
        "Incensed",
        "Irritated",
        "Mad",
        "Offended",
        "Outraged",
        "Patronized",
        "Peeved",
        "Perturbed",
        "Pissed Off",
        "Provoked",
        "Rebellious",
        "Repulsed",
        "Resentful",
        "Ridiculed",
        "Sabotaged",
        "Seething",
        "Smothered",
        "Spiteful",
        "Stifled",
        "Strangled",
        "Throttled",
        "Uptight",
        "Used",
        "Vengeful",
        "Vindictive"
      ]
    },

    "Meridians": {

      "Spleen (Pancreas)": [
    
        "BL20",
        "LV13",
        "SP3-source",
        "SP4",
        "SP8",
        "SP1",
        "SP21",
        "SP3-Horay",
        "SP9",
        "SP8",
        "SP5",
        "SP2"
      ],
      // "Heart Governor"
      "Triple Heater": [
        "BL22",
        "CV5",
        "TW4",
        "TW5",
        "TW7",
        "TW1",
        "TW22",
        "TW16",
        "TW3",
        "TW2",
        "TW1",
        "TW6",
        "TW10"
      ],
      "Stomach": [
        "BL21",
        "CV12",
        "ST42",
        "ST40",
        "ST34",
        "ST1",
        "ST42",
        "ST36",
        "ST41",
        "ST43",
        "ST44",
        "ST45",
        "ST36"
      ],
      "Bladder": [
        "BL28",
        "CV3",
        "BL64",
        "BL58",
        "BL63",
        "BL1",
        "BL67",
        "BL66",
        "BL65",
        "BL60",
        "BL58",
        "BL40"
      ],
      "Liver": [
        "BL18",
        "LV14-front",
        "LV3",
        "LV5",
        "LV6",
        "LV1-entry",
        "LV14-exit",
        "LV1-horary",
        "LV2",
        "LV8",
        "LV4"],
      "Lung": [
        "BL13",
        "LU1-front",
        "LU9",
        "LU7-luo",
        "LU6",
        "LU1-entry",
        "LU7-exit",
        "LU8",
        "LU11",
        "LU5",
        "LU10"
      ],
      "Heart":[
        "BL15",
        "CV14",
        "HT7",
        "HT5",
        "HT6",
        "HT1",
        "HT9",
        "HT8",
        "HT4",
        "HT3"
      ],
      "Kidney": [
        "BL23",
        "GB25",
        "KI3",
        "KI4",
        "KI5",
        "KI1",
        "KI22",
        "KI10",
        "KI2",
        "KI7",
        "KI"
      ],
      "Gallbladder": [
        "BL19",
        "GB24",
        "GB40",
        "GB37",
        "GB36",
        "GB1",
        "GB38",
        "GB41-exit",
        "GB41Horary",
        "GB44",
        "GB43",
        "GB34"
      ],
      "Pericardium": [
        "BL14",
        "CV17",
        "P7",
        "P6",
        "P4",
        "P1",
        "P6",
        "P8",
        "P9",
        "P5",
        "P3"
      ],
      "Large Intestine": [
        "BL25",
        "ST25",
        "LI4-source",
        "LI6",
        "LI7",
        "LI4-entry",
        "LI20",
        "LI1",
        "LI5",
        "LI2",
        "LI3",
        "LI11"
      ],
      "Small Intestine": [
        "BL27",
        "CV4",
        "SI4",
        "SI7",
        "SI6",
        "SI1",
        "SI19",
        "SI5",
        "SI8",
        "SI3",
        "SI2"
      ],
      // "Multiple Meridians"
    },



    "Body Area": {
      "Endocrine": [
        "Adrenals",
        "Ovaries",
        "Pancreas",
        "Pineal Gland",
        "Pituitary Gland",
        "Testes",
        "Thymus",
        "Thyroid"
      ],
      "Brain": [
        "Cerebellum",
        "Hypothalamus",
        "Thalamus",
        "Pituitary Gland",
        "Cerebral Cortex",
        "Brainstem",
        "Basal Ganglia",
        "Limbic System"

      ],
      "Organs": [
        "Bladder",
        "Gallbladder",
        "Heart",
        "Kidneys",
        "Large Intestine",
        "Liver",
        "Lung",
        "Small Intestine",
        "Spleen",
        "Stomach"
      ],
      "Hormones": [
        "Corticotrophin",
        "Dopamine",
        "Gonadotropin",
        "Ghrelin",
        "Growth Hormone",
        "Oxytocin",
        "Somatostatin",
        "IGF-1",
        "Angiotensinogen",
        "Thyrotropin",
        "Adrenocorticotropic Hormone (ACTH)",
        "FSH (Follicle-Stimulating Hormone)",
        "LH (Luteinizing Hormone)",
        "Prolactin",
        "TSH (Thyroid-Stimulating Hormone)",
        "ADH (Antidiuretic Hormone)",
        "T4 (Thyroxine)",
        "T3 (Triiodothyronine)",
        "RT3 (Reverse Triiodothyronine)",
        "Calcitonin",
        "PTH (Parathyroid Hormone)",
        "Cortisol",
        "Aldosterone",
        "DHEA (Dehydroepiandrosterone)",
        "Adrenaline (Epinephrine)",
        "Noradrenaline (Norepinephrine)",
        "Insulin",
        "Glucagon",
        "Estrogen",
        "Progesterone",
        "Testosterone",
        "Leptin",
        "Adiponectin",
        "Plasminogen",
        "Angiotensin",
        "Erythropoietin",
        "Renin",
        "D"
      ]
    },
    "Cells": {
      "Mesoderm": [
        "Barrier function cells: Forms a barrier between the body and the environment within that part of the body.",
        "Blood system cells: Key part of the body's immune system.",
        "Contractile cells: The smooth muscle cells of the heart.",
        "Extracellular matrix cells: Fills the spaces between cells and binds cells and tissues together.",
        "Germs cells: A reproductive system cell.",
        "Immune system cells: Protects us from infections and diseases.",
        "Interstitial cells: Any cell that lies between other cells.",
        "Mesoderm cell: The middle layer of the cell between ectoderm and endoderm.",
        "Metabolism cells: Life sustaining chemical transformations with the cells.",
        "Nurse cells: Reside in the bone marrow as assist in the development of red blood cells.",
        "Storage cells: A cell whose energy can be renewed by passing a current through it in the direction opposite to that of the flow of current generated by the cell."

      ],
    },

    "Bones": {

      "Long Bones": [
        "Femur - bone from the hip joint to the knee",
        "Humerus - between the elbow and shoulder",
        "Metacarpals - first bone of the thumb",
        "Metatarsals - group of 5 long bones in feet",
        "Phalanges - digital bones in the hands",
        "Tibia - one of two bones in the lower leg"

      ],
      "Short Bones": [
        "Carpals - come together to form a joint with the Long bones of the forearm",
        "Tarsals - ankle and foot bones"
      ],
      "Flat Bones": [
        "Coxae - part of the hip bone area",
        "Cranium - 8 bones in the skull",
        "Ribs - bones in the rib cage",
        "Scapula - shoulder blades",
        "Sternum - bone at the front and center of the chest"
      ],
      "Irregular Bones": [
        "Mandible - jaw bone",
        "Sacrum - triangular bone just below the lumbar spine",
        "Vertebrae - houses the spinal canal"
      ],
      "Sesamoid Bones": [
        "Patella - knee cap bone",
        "Pisiform - small rounded carpal bones situated where the palm of the hand meets the outer edge of the wrist"
      ],
      "Pneumatic Bones": [
        "Ethmoid - a square bone at the root of the nose",
        "Maxilla - forms the upper jaw",
        "Sphenoid - compound bone that forms the base of the cranium"
      ]
    },

    "Blood": {
      "White Cells": [
        "Basophils",
        "Eosinophils",
        "Lymphocytes",
        "Monocytes",
        "Neutrophils"
      ],
      "Hormones": [
        "Corticotrophin",
        "Dopamine",
        "Gonadotropin",
        "Ghrelin",
        "Growth Hormone",
        "Oxytocin",
        "Somatostatin",
        "IGF-1",
        "Angiotensinogen",
        "Thyrotropin",
        "Adrenocorticotropic Hormone (ACTH)",
        "FSH (Follicle-Stimulating Hormone)",
        "LH (Luteinizing Hormone)",
        "Prolactin",
        "TSH (Thyroid-Stimulating Hormone)",
        "ADH (Antidiuretic Hormone)",
        "T4 (Thyroxine)",
        "T3 (Triiodothyronine)",
        "RT3 (Reverse Triiodothyronine)",
        "Calcitonin",
        "PTH (Parathyroid Hormone)",
        "Cortisol",
        "Aldosterone",
        "DHEA (Dehydroepiandrosterone)",
        "Adrenaline (Epinephrine)",
        "Noradrenaline (Norepinephrine)",
        "Insulin",
        "Glucagon",
        "Estrogen",
        "Progesterone",
        "Testosterone",
        "Leptin",
        "Adiponectin",
        "Plasminogen",
        "Angiotensin",
        "Erythropoietin",
        "Renin",
        "D"
      ],
      "Comp Blood Cnt": [
        "Differential blood count",
        "Hematocrit (HCT)",
        "Hemoglobin concentration",
        "Platelet count",
        "Prothrombin time",
        "Red blood cells",
        "White blood cells"
      ],
      "Blood Comp.": [
        "17-Hydroxycorticosteroids",
        "17-Hydroxyprogesterone",
        "Acetoacetate",
        "Acetone",
        "Acetylcholine",
        "Adenosine Triphosphate",
        "Adrenocorticotrophic",
        "Alanine",
        "Albumin",
        "Aluminum",
        "Alpha I-Antitrupsin",
        "Alpha 2-Macroglobulin",
        "Alpha-Aminobutyric Acid",
        "Amino Acids",
        "Ammonia NitrogencAMP",
        "Androsterone",
        "Androstenedione",
        "Angiotensin I",
        "Angiotensin II",
        "Antibodies",
        "Arginine",
        "Arsenic",
        "Ascorbic Acid (Vitamin C)",
        "Aspartic Acid",
        "Bicarbonate",
        "Bile Acids",
        "Beta Globulin Gamma Globulin",
        "Beta-2-Microglobulin",
        "Beta-Carotene",
        "Bile Acids",
        "Blood Urea Nitrogen",
        "Biotin (Vitamin H)",
        "Bromide",
        "Bradykinin",
        "Calciferol (Vitamin D2)",
        "Calcitonin",
        "Calcium",
        "Carbon Dioxide",
        "Carboxyhemoglobin",
        "Carcinoembryonic Antigen",
        "Carotenoids",
        "Cephalin",
        "Ceruloplasmin",
        "Chloride",
        "Cholecalciferol (Vitamin D3)",
        "Cholecystokinin",
        "Cholesterol",
        "Choline",
        "Chorionic Gonadotropin",
        "Citric Acid",
        "Cirtulline",
        "Coagulation Factors",
        "Cobalamin (Vitamin B12)",
        "Cocarboxylase",
        "Complement System",
        "Compound S",
        "Copper",
        "Corticosteroids",
        "Cortucisteribe",
        "Cortisol",
        "C-Peptide",
        "C-Reactive Protein",
        "Creatine",
        "Creatinine",
        "Cyanide",
        "Cysteine",
        "DHEA Sulfate-Female",
        "DHEA sulfate-male",
        "DHEA sulfate-male",
        "DNA",
        "Dihydrotestosterone (DHT)",
        "Diphosphoglycerate",
        "Dopalome",
        "Enzymes, Total",
        "Epidermal Growth Factor",
        "Ephinephrine",
        "Erythrocytes",
        "Erythropoietin",
        "Estrogen",
        "Estridol-E3",
        "Estradiol-E2",
        "Estrone-E1",
        "Ethanol",
        "Fat, Neutral",
        "Fatty Acids, Esterified",
        "Fatty Acids, Nonesterified Free",
        "Fatty Acids, Total",
        "Ferritin",
        "Flavin Adnine Dinucleotide",
        "Fluoride",
        "Folate",
        "Folic Acid",
        "Fructose",
        "Furosemide Glucuronide",
        "Galactose",
        "Gastric Inhibitory Peptide",
        "Gastrin",
        "Globulin",
        "Glucagon",
        "Glucosamine",
        "Glucose",
        "Glucuronic Acid",
        "Glutamic Acid",
        "Glutamine",
        "Glutathione, Reduced",
        "Glycerol, Free",
        "Glycine",
        "Glycogen",
        "Glycoprotein, AcidcGMP",
        "Gonadotropin-Releasing Hormone",
        "Guanidine",
        "Haptoglobin",
        "Hemoglobin",
        "Hexosephosphate P",
        "Histamine",
        "Histidine",
        "Hydrogen Ion",
        "Indican",
        "Inositol",
        "Insulin",
        "Insulin-Like Growth Factor",
        "Iodine, Total",
        "Iron",
        "Isoleucine",
        "Ketone Bodies",
        "L-Lactate",
        "Lead",
        "Lechithin",
        "Leptin",
        "Leucine",
        "Leukocytes",
        "Lipase P",
        "Lipids, Total",
        "Lipoprotein",
        "Lithium",
        "Lysine",
        "Lysozyme",
        "Magnesium",
        "Malic Acid",
        "Manganese",
        "Melatonin",
        "Methemoglobin",
        "Methionine",
        "Methyl Guanidine",
        "MIP-1a",
        "MIP-1b",
        "Mucopolusaccharides",
        "Mucoproteins",
        "Nerve Growth Factor",
        "Niacin Nitrogen",
        "Norephinephrine",
        "Nucleotide, Total",
        "Ornithine",
        "Oxalate",
        "Oxygen",
        "Oxytocin",
        "Pancreatic Polypeptide",
        "Panthothenic Acid (Vitamin B5)",
        "Para-Aminobenzoic Acid",
        "Parathyroid Hormone",
        "Pentose, Phosphorated",
        "Phenol, Free",
        "Phenylalanine",
        "Phospholipid",
        "Phosphatase, Acid, Prostatic",
        "Phosphorus",
        "Phytanic Acid",
        "Platelet-Derived Growth Factor",
        "Platelets",
        "Polysaccharides, Total",
        "Potassium",
        "Pregnenolone",
        "Progesterone-Female",
        "Progesterone-Male",
        "Proinsulin",
        "Prolactin-Female",
        "Prolactin-Male",
        "Proline",
        "Prostaglandins",
        "Protein, Total",
        "Protoporphyrin",
        "Prostate Specific Antigen",
        "Pseudoglobulin I",
        "Pseudoglobulin II",
        "Purine, Total",
        "Pyridoxine (Vitamin B6)",
        "Pyrimidine Nucleotides",
        "Pyruvic Acid",
        "Rantes",
        "Relaxin",
        "Retinol (Vitamin A)",
        "Riboflavin (Vitamin B2)",
        "RNA",
        "Secretin",
        "Serine",
        "Serotonin",
        "Silicon",
        "Sodium",
        "Solids, Total",
        "Somatotropin (Growth Hormone)",
        "Sphingomyelin",
        "Succinic Acid",
        "Sugar, Total",
        "Sulfates, Inorganic",
        "Sulfur, Total",
        "Taurine",
        "Testosterone-Female",
        "Testosterone-Male",
        "Thiamine (Vitamin B1)",
        "Thiocyanate",
        "Threonine",
        "Throid Hormones",
        "Throtropin-Releasing Hormone",
        "Throxine",
        "Throxine-Tocopherol (Vitamin E)",
        "Transcortin",
        "Transferrin",
        "Triglycerides",
        "Triiodothyronine",
        "Tryptophan",
        "Tyrosine",
        "Urea",
        "Uric Acid",
        "Valine",
        "Vasointestinal Peptide",
        "Vasopressin",
        "Water",
        "Zinc"


      ]
    },
    "Actions": [
      "Ask for help",
      "Be creative",
      "Change diet/Eat healthy",
      "Change environment",
      "Change thoughts",
      "Connect with others",
      "Cut painful connections",
      "Dance",
      "Detoxify-emotionally/physically",
      "Dream",
      "Do not take things personally",
      "Eat healthy/change diet",
      "Engage in a hobby",
      "Enjoy uplifting music",
      "Exercise",
      "Express feelings",
      "Express yourself",
      "Give",
      "Help someone else out",
      "Learn and grow",
      "Love yourself",
      "Make yourself a priority",
      "Meditate",
      "Play",
      "Pursue interests/passions",
      "Receive",
      "Rest",
      "Seek professional help",
      "Speak your truth",
      "Spend time in nature",
      "Spend time with an animal",
      "Take a vacation",
      "Take healthy risks",
      "Trust",
      "Volunteer",
      "Write down your intentions"

    ],
    "Reproductive": {
      "Female": [
        "Batholins glands",
        "Cervix",
        "Clitoris",
        "Divine Feminine Energy",
        "Eggs",
        "Estrogen - low/high levels",
        "Fallopian tubes – left/right/both",
        "Follicle stimulating hormone – (FSH) - low/high",
        "Labia majora",
        "Labia minora",
        "Leutinizing hormone – (LH) - low/high",
        "Menopause",
        "Menstrual Cycle",
        "Ovaries – left/right/both",
        "Pregnancy",
        "Progesterone - low/high levels",
        "Uterus",
        "Vagina",
        "Womb"
      ],
      "Male": [
        "Bladder",
        "Bulbourethral gland",
        "Divine Masculine  Energy",
        "Ejaculatory ducts",
        "Epididymis",
        "Follicle Stimulating Hormone – (FSH) - low/high",
        "Luteinizing Hormone – (LH) - low/high",
        "Penis",
        "Prostate",
        "Scrotum",
        "Seminole vessels",
        "Sperm",
        "Testicle – left/right/both",
        "Testosterone – low/high levels",
        "Urethra",
        "Vas deferens"
      ],
    },

  }
}