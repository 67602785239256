import React from 'react';
import { Typography, Box } from '@mui/material';
import ModuleChart from '../ModuleChart/ModuleChart';
import { ChartPageType } from '../../data/types';
import { SUB_TEXT_TYPOGRAPHY_PROPS } from '../../utils';
import { NoInfoPopUp } from '../../data/popup';

interface ExerciseProps {
    height: number;
    width: number;
}

const DurationData: ChartPageType = {
    chart: [
        { label: '10', value: 1, id: 1, color: '#DFD3E7' },
        { label: '20', value: 1, id: 2, color: '#CEBCDB' },
        { label: '30', value: 1, id: 3, color: '#BEA6CF' },
        { label: '40', value: 1, id: 4, color: '#AD90C2' },
        { label: '50', value: 1, id: 5, color: '#9D79B6'},
        { label: '60+', value: 1, id: 6, color: '#8C63AA' },

    ],
    level: 0,
    subPages: {},
    disableClick: true,
    textSizeOverride: 15,
    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Duration</Typography>
}

const TimesPerWeekData: ChartPageType = {
    chart: [
        { label: '1', value: 1, id: 1, color: '#E1D6E9', disableTextRotation: true},
        { label: '2', value: 1, id: 2, color: '#D3C3DE', disableTextRotation: true },
        { label: '3', value: 1, id: 3, color: '#C5B0D4', disableTextRotation: true },
        { label: '4', value: 1, id: 4, color: '#B69CC9', disableTextRotation: true },
        { label: '5', value: 1, id: 5, color: '#A889BF', disableTextRotation: true },
        { label: '6', value: 1, id: 6, color: '#9A76B4', disableTextRotation: true },
        { label: '7', value: 1, id: 7, color: '#8C63AA', disableTextRotation: true },
    ],
    level: 0,
    subPages: {},
    disableClick: true,
    textChartInstructions: <Typography {...SUB_TEXT_TYPOGRAPHY_PROPS} >Times per week</Typography>
}


const ExerciseTypeData: ChartPageType = {
    chart: [
        { label: 'Horseback Riding', value: 1, id: 0, color: '#8C63AA', popupContent: <NoInfoPopUp /> },
        { label: 'Biking', value: 1, id: 1, color: '#E0D5E8', popupContent: <NoInfoPopUp /> },
        { label: 'Walking', value: 1, id: 2, color: '#8C63AA', popupContent: <NoInfoPopUp /> },
        { label: 'Running', value: 1, id: 3, color: '#E0D5E8', popupContent: <NoInfoPopUp /> },
        { label: 'Weights', value: 1, id: 4, color: '#8C63AA', popupContent: <NoInfoPopUp /> },
        { label: 'Yoga', value: 1, id: 5, color: '#E0D5E8', popupContent: <NoInfoPopUp /> },
        { label: 'Stretching', value: 1, id: 6, color: '#8C63AA', popupContent: <NoInfoPopUp /> },
        { label: 'Team Sports', value: 1, id: 7, color: '#E0D5E8', popupContent: <NoInfoPopUp /> },
        { label: 'Swimming', value: 1, id: 8, color: '#8C63AA', popupContent: <NoInfoPopUp /> },
        { label: 'Skiing', value: 1, id: 9, color: '#E0D5E8', popupContent: <NoInfoPopUp /> },
        { label: 'Soccer', value: 1, id: 10, color: '#8C63AA', popupContent: <NoInfoPopUp /> },
        { label: 'Hiking', value: 1, id: 11, color: '#E0D5E8', popupContent: <NoInfoPopUp /> },
        { label: 'Climbing', value: 1, id: 12, color: '#8C63AA', popupContent: <NoInfoPopUp /> },
        { label: 'Rebounding', value: 1, id: 13, color: '#E0D5E8', popupContent: <NoInfoPopUp /> },
        { label: 'Cleaning', value: 1, id: 14, color: '#8C63AA', popupContent: <NoInfoPopUp /> },
        { label: 'Yardwork', value: 1, id: 15, color: '#E0D5E8', popupContent: <NoInfoPopUp /> },
        { label: 'Jump Rope', value: 1, id: 16, color: '#8C63AA', popupContent: <NoInfoPopUp /> },
        { label: 'Hula Hooping', value: 1, id: 17, color: '#E0D5E8', popupContent: <NoInfoPopUp /> },
        { label: 'Personal Trainer', value: 1, id: 18, color: '#8C63AA', popupContent: <NoInfoPopUp /> },
        { label: 'Not Listed', value: 1, id: 19, color: '#E0D5E8', popupContent: <NoInfoPopUp /> },
    ],
    level: 0,
    textSizeOverride: 15,
    subPages: {},

}

const ExercisePage: React.FC<ExerciseProps> = ({ height, width }) => {
    const top_chart_heights = height / 2;
    const bottom_chart_heights = top_chart_heights;
    const chart_widths = width;
    return (
        <>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: top_chart_heights * 1.5, width: width / 1.25 }}>
                <Box style={{ display: 'flex', height: '100%', width: '100%', marginLeft: '100px', marginBottom: '50px' }}>
                    <ModuleChart
                        height={bottom_chart_heights * 1.5}
                        width={chart_widths}
                        chartData={ExerciseTypeData}
                        chartModule={"Nutrition"}
                        item={"Exercise"}
                        subitem={null} />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '35%', 
                 marginLeft: '100px' }}>
                    <ModuleChart
                        height={top_chart_heights / 1.5}
                        width={chart_widths}
                        chartData={DurationData}
                        chartModule={"Nutrition"}
                        item={"Exercise"}
                        subitem={null} />
                    <ModuleChart
                        height={top_chart_heights / 1.5}
                        width={chart_widths}
                        chartData={TimesPerWeekData}
                        chartModule={"Nutrition"}
                        item={"Exercise"}
                        subitem={null} />
                </Box>
            </Box >
        </>
    );
}
export default ExercisePage;
