import { WordListMappingType } from "../types";

export const TraumaAnimalWordListMap: WordListMappingType = {
  "Trauma Animal": {
    "Body Imbalance": {
      "Virus": [
        "Adenovirus",
        "Avian Flu - (H5N1)",
        "cobra venom",
        "Coronavirus",
        "Crete Venom",
        "Bordatella",
        "Equine Encephalomyelitis - (Eastern/Western/Venezuelan)",
        "Equine Herpes Virus - (EHV-1)",
        "Equine Infectious Anemia - (EIA)",
        "Equine Influenza",
        "Equine Viral Arteritis - (EVA)",
        "Feline Calcivirus - (FCV)",
        "Feline Immunodeficiency - (FIV)",
        "Feline Infectious Peritonitis - (FIP)",
        "Feline Leukemia - (FELV)",
        "Feline Rhinotracheitis Calic & Panleukopenia - (FRCP)",
        "Feline Urologic Syndrome - (FUS)",
        "Giardia",
        "Hepatitis",
        "Parainfluenza",
        "Porcine Circovirus",
        "Porcine Epidemic Diarrhea Virus",
        "Porcine Reproductive Respiratory Syndrome",
        "Pneumonia",
        "Pseudorabies - (PRV)",
        "Rabies",
        "Respiratory Virus",
        "Rhinopneumonitis - (EHV-4)",
        "Ringworm",
        "Vesicular Stomatitis Virus - (VSV)",
        "West Nile"
      ],
      "Bacteria": [
        "Aeromonas",
        "Brucellosis",
        "Campylobacteriosis",
        "Clostridia",
        "Cytobacter",
        "Ecoli",
        "Enterobacter",
        "Klebsiella",
        "Leptospirosis",
        "Lymes",
        "Mycobacteria",
        "Pasteurella",
        "Potomac Horse Fever",
        "Proteus",
        "Pseudomonas",
        "Respiratory Infection",
        "Salmonella",
        "Staphylococcal Infection",
        "Serratia Marcescens",
        "Strangles",
        "Streptococcal Infection",
        "Tetanus"
      ],
      "Parasites": [
        "Bloodworms",
        "Bots",
        "Bolts Filarial",
        "Coccidian",
        "Cryptosporidium",
        "Dark Implants (see implant protocol)",
        "Energetics in Pharmaceuticals",
        "Fleas",
        "Giardia",
        "Hairworms",
        "Heartworms",
        "Hookworms",
        "Lice",
        "Lungworms",
        "Mange",
        "Mites",
        "Palisade Worms",
        "Pinworms",
        "Red Worms",
        "Ringworm",
        "Roundworms",
        "Scabies",
        "Stomach Worms",
        "Strongyles",
        "Strangles",
        "Tapeworms",
        "Threadworms",
        "Tics",
        "Whipworms"
      ],
      "Fungus": [
        "Aspergillus",
        "Candida Auris",
        "Chartarum",
        "Cladosporium",
        "Mold",
        "Peniciliium",
        "Ringworm",
        "Stachylbotys",
        "Tinea"
      ],
      "Disease": [
        "Anaplasmosis",
        "Anthrax",
        "Antrophic Rhinitis",
        "Avian Influenza",
        "Blastomyosis",
        "Bovine Popular Stomatitis",
        "Bovine Rhinotrachetis",
        "BRSV",
        "Bursal Disease",
        "Calf Scours",
        "Canine Ehrlichiosis",
        "Cancer",
        "Caprine Encephalitis",
        "Caseous Lymphadentis",
        "Cholera",
        "Chronic Wasting Disease",
        "Colic",
        "Contagious Agalactia Mycoplasma",
        "Cryptosporidiosis",
        "Cushings",
        "Cysticerosis",
        "Dermatophilosis",
        "Diabetes",
        "Diphtheria",
        "Duck Plague",
        "Duck Viral Hepatitis",
        "Dysentery",
        "Erysipelas",
        "Epizootic Hemorrhagic Disease",
        "Foot And Mouth Disease",
        "Fowl Pox",
        "Heaves",
        "Histoplasmosis",
        "Hydatid Disease",
        "IBR",
        "Johnes Disease",
        "Leishmaniasis",
        "Leptospirosis",
        "Leucosis",
        "Lleitis",
        "Listeriosis",
        "Malignant Catarrhal Fever",
        "Marek's Disease",
        "Mycoplasma Synovae",
        "Newcastle Disease",
        "Ovine Pulmonary Adenomatosis",
        "Paramyxovirus",
        "Pasteurellosis",
        "Pink Eye",
        "Plague",
        "Rabbit Hemorrhagic Disease",
        "Ram Epididymitis",
        "Rocky Mountain Spotted Fever",
        "Scrapie",
        "Swine Enteric Coronavirus Disease",
        "Thyphoid",
        "Toxoplasmosis",
        "Transmissible Gastroenteritis (TGE)",
        "Trichomoniasis",
        "Tuberculosis",
        "Tularemia",
        "Vesticular Exanthema",
        "Vibrosis",
        "Blue Tongue",
        "Maedi-Visna",
        "Cholera",
        "Black Leg"
      ]
    },

    "Blood": {

      "White Cells": [
        "Basophils",
        "Eosinophils",
        "Lymphocytes",
        "Monocytes",
        "Neutrophils"
      ],

      "Hormones": [
        "Corticotrophin",
        "Dopamine",
        "Gonadotropin",
        "Ghrelin",
        "Growth Hormone",
        "Oxytocin",
        "Somatostatin",
        "IGF-1",
        "Angiotensinogen",
        "Thyrotropin",
        "Adrenocorticotropic Hormone (ACTH)",
        "FSH (Follicle-Stimulating Hormone)",
        "LH (Luteinizing Hormone)",
        "Prolactin",
        "TSH (Thyroid-Stimulating Hormone)",
        "ADH (Antidiuretic Hormone)",
        "T4 (Thyroxine)",
        "T3 (Triiodothyronine)",
        "RT3 (Reverse Triiodothyronine)",
        "Calcitonin",
        "PTH (Parathyroid Hormone)",
        "Cortisol",
        "Aldosterone",
        "DHEA (Dehydroepiandrosterone)",
        "Adrenaline (Epinephrine)",
        "Noradrenaline (Norepinephrine)",
        "Insulin",
        "Glucagon",
        "Estrogen",
        "Progesterone",
        "Testosterone",
        "Leptin",
        "Adiponectin",
        "Plasminogen",
        "Angiotensin",
        "Erythropoietin",
        "Renin",
        "D"
      ],

      "Complete Blood Count": [
        "Basophils",
        "Eosinophils",
        "Lymphocytes",
        "Monocytes",
        "Neutrophils"
      ],
      "Blood Comp.": [
        "17-Hydroxycorticosteroids",
        "17-Hydroxyprogesterone",
        "Acetoacetate",
        "Acetone",
        "Acetylcholine",
        "Adenosine Triphosphate",
        "Adrenocorticotrophic",
        "Alanine",
        "Albumin",
        "Alpha 2-Macroglobulin",
        "Alpha I-Antitrupsin",
        "Alpha-Ketonic Acids",
        "Amino Acids",
        "Ammonia Nitrogen",
        "Angiotensin I",
        "Angiotensin II",
        "Arsenic",
        "Ascorbic Acid - (Vitamin C)",
        "Aspartic Acid",
        "Bicarbonate",
        "Bile Acids",
        "Bilirubin",
        "Biotin - (Vitamin H)",
        "Blood Urea Nitrogen",
        "Bradykinin",
        "Bromide",
        "Cadmium",
        "Calciferol - (Vitamin D2)",
        "Calciferol - (Vitamin D3)",
        "Calcitonin",
        "Carbon Dioxide",
        "Carboxyhemoglobin",
        "Carcinoembryonic Antigen",
        "Carotenoids",
        "Cephalin",
        "Ceruloplasmin",
        "Chloride",
        "Cholecalciferol - (Vitamin D3)",
        "Cholecystokinin",
        "Cholesterol",
        "Choline",
        "Chorionic Gonadotropin",
        "Citric Acid",
        "Citrulline",
        "Coagulation Factors",
        "Cobalamin - (Vitamin B12)",
        "Cocarboxylase",
        "Complement System",
        "Compound S",
        "Copper",
        "Corticosteroids",
        "Corticotropin-Releasing Hormone",
        "Cortisol",
        "Creatine",
        "Creatinine",
        "Cyanide",
        "Cysteine",
        "Dehydroepiandrosterone - (DHEA)",
        "DHEA Sulfate-Female",
        "DHEA Sulfate-Male",
        "Dihydrotestosterone - (DHT)",
        "Diposphoglycerate",
        "DNA",
        "Dopamine",
        "Enzymes, Total",
        "Epidermal Growth Factor",
        "Epinephrine",
        "Erythrocytes",
        "Erythropoietin",
        "Estradiol-E2",
        "Estrone-E1",
        "Estrogen",
        "Ethanol",
        "Fatty Acids, Esterified",
        "Fatty Acids, Nonesterified Free",
        "Fatty Acids, Total",
        "Ferritin",
        "Flavin Adnine Dinucleotide",
        "Fluoride",
        "Folate",
        "Folic Acid",
        "Fructose",
        "Furosemide Glucuronide",
        "Galactose",
        "Gastric Inhibitory Peptide",
        "Gastrin",
        "Globulin",
        "Glucagon",
        "Glucosamine",
        "Glucose",
        "Glucuronic Acid",
        "Glutamic Acid",
        "Glutamine",
        "Glutathione, Reduced",
        "Glycerol, Free",
        "Glycine",
        "Glycoprotein, Acid",
        "Gonadotropin-Releasing Hormone",
        "Guanidine",
        "Haptoglobin",
        "Hemoglobin",
        "Hexosephosphate",
        "Histamine",
        "Histidine",
        "Hydrogen Ion",
        "Iodine, Total",
        "Iron",
        "Isoleucine",
        "Ketone Bodies",
        "Lactate",
        "Lecithin",
        "Leptin",
        "Leucine",
        "Leukocytes",
        "Leukotrienes",
        "Lipase P",
        "Lipids, Total",
        "Lipoprotein",
        "Lithium",
        "Lysine",
        "Lysozyme",
        "Magnesium",
        "Malic Acid",
        "Manganese"
      ]
    },


    "Meridians": {

      "Spleen (Pancreas)": [{
        title: "Horse", words: [
          "K10",
          "K7",
          "K3"
        ]
      },
      {
        title: "Cat", words: [
          "S17", "S19", "SI11"
        ]
      }
      ],
      // "Large Intestine": [{
      //   title: "Horse (Misc.)", words: [

      //   ]
      // }],
      "Stomach": [{
        title: "Horse", words: [
          "S7",
          "S4",
          "S13",
          "S14",
          "S19",
          "S25",
          "S26",
          "S41",
          "S36",
          "S34"
        ]
      },
      {
        title: "Dog", words: [
          "S7", "S4", "S6", "S19", "S25", "S26S41", "S44", "S36", "S19", "S14", "S13", "S34",
          "LI20", "LI15", "LI14", "LI12", "LI11", "LI10", "LI1",
          "L15", "L14", "L7", "L5",
          "L15", "L14", "L7", "L5"
        ]
      },
      {
        title: "Cat", words: [
          "ST4", "ST10", "ST34", "ST36"
        ]
      }


      ],
      "Lung": [{
        title: "Cat", words: [
          "LU6", "LU9",
          "TW4", "TW10",
          "P6",
          "LV3", "SP6", "SP10", "K13",
          "Erchien", "Zhijian", "Weidaan"]

      }],
      "Heart": [{
        title: "Dog", words: [
          "H7",
          "H3",
          "P6",
          "Yang tang",
          "Umbilicus"
        ]
      }],
      // "Small Intestine": [{
      //   title: "Horse (Misc.)", words: [

      //   ]
      // }],
      "Bladder": [{
        title: "Horse", words: [
          "B10",
          "B17",
          "B13",
          "B11",
          "B60",
          "B59",
          "B40",
          "B32",
          "B31",
          "B25",
          "B23",
          "B21",
          "B20"
        ]
      },
      {
        title: "Dog", words: [
          "B25", "B23", "B21", "B20", "B17", "B13", "B11", "B67", "B60", "B59", "B40", "B32", "B31",
          "CV24", "CV22", "CV17", "CV12", "CV9", "CV6", "CV7", "CV3", "CV4",
          "SP18", "SP15", "SP9", "SP10", "SP8", "SP6", "SP3", "SP4",
          "SI12", "SI9",
          "Liv8", "Liv3",
        ]
      },
      {
        title: "Cat", words: [
          "BL1", "BL15", "BL18", "BL21", "BL25", "BL32", "BL60"
        ]
      }


      ],

      "Kidney": [{
        title: "Dog", words: [
          "K10", "K7", "K3"
        ]
      }

      ],
      // "Heart Governor"
      "Triple Heater": [{
        title: "Horse", words: [
          "TW17",
          "TW21",
          "TW14",
          "TW5"
        ]
      },
      {
        title: "Dog", words: [
          "TW21", "TW17", "TW14", "TW5"
        ]
      }

      ],
      "Gall Bladder": [{
        title: "Horse", words: [
          "GB20",
          "GB14",
          "GB21",
          "GB40",
          "GB34",
          "GB33",
          "GB29",
          "GB30"
        ]
      },
      {
        title: "Dog", words: [
          "GB20", "GB14", "GB40", "GB34", "GB33", "GB29", "GB30"
        ]
      },
      {
        title: "Cat", words: [
          "GB1", "GB21", "GB30", "GB31", "GB34"]
      }
      ],
      "Liver": [{
        title: "Horse", words: [
          "LI20",
          "LI15",
          "LI14",
          "LI12",
          "LI5",
          "LI4",
          "LI10",
          "LI11",
          "SI12",
          "H3",
          "L5",
          "L7",
          "Chintietou",
          "P6",
          "Yintang",
          "Haotietou",
          "K10",
          "K7",
          "K3",
          "LIV8",
          "LIV3",
          "SP10",
          "SP8",
          "SP6",
          "SP4SP9",
          "SP3",
          "SP15",
          "CV17",
          "CV12",
          "CV9",
          "CV8",
          "CV7",
          "CV6",
          "CV3",
          "CV4",
          "CV22"

        ]
      },
      {
        title: "Cat", words: [
          "LI11", "LI18"]
      }
      ],
      // "Pericardium": [{
      //   title: "Horse", words: [

      //   ]
      // }],


      // "Multiple Meridians": [{
      //   title: "Horse ", words: [

      //   ]
      // }],
      "Governing Vessel": [
      {
        title: "Horse", words: [
          "GV20",
          "GV23",
          "GV27",
          "GV26",
          "GV24",
          "GV14",
          "GV4",
          "GV8"
        ]
      },
      {
        title: "Dog", words: [
          "GV20", "GV23", "GV26", "GV27", "GV4", "GV5 & 6", "GV8", "GV14", "GV1", "GV2"
        ]
      },
      {
        title: "Cat", words: [
          "GV1", "GV2", "GV4", "GV8", "GV12", "GV14", "GV25", "GV26",
          "CV6", "CV12", "CV22"

        ]
      }

      ],
    },




    "Body Area": {
      "Organs": [
        "Bladder",
        "Gallbladder",
        "Heart",
        "Kidneys",
        "Large Intestine",
        "Liver",
        "Lung",
        "Small Intestine",
        "Spleen",
        "Stomach"
      ],
      "Brain": [
        "Cerebellum",
        "Hypothalamus",
        "Thalamus",
        "Pituitary Gland",
        "Cerebral Cortex",
        "Brainstem",
        "Basal Ganglia",
        "Limbic System"

      ],
      "Endocrine": [
        "Adrenals",
        "Ovaries",
        "Pancreas",
        "Pineal Gland",
        "Pituitary Gland",
        "Testes",
        "Thymus",
        "Thyroid"
      ],

    },


    "Bones": {

      "Long Bones": [
        "Femur: Bone from the hip joint to the knee",
        "Humerus: Between the elbow and shoulder",
        "Metacarpals: First bone of the thumb",
        "Metatarsals: Group of 5 long bones in feet",
        "Phalanges: Digital bones in the hands",
        "Tibia: One of two bones in the lower leg"

      ],
      "Short Bones": [
        "Carpals: Come together to form a joint with the Long bones of the forearm",
        "Tarsals: Ankle and foot bones"
      ],
      "Flat Bones": [
        "Coxae: Part of the hip bone area",
        "Cranium: Eight bones in the skull",
        "Ribs: Bones in the rib cage",
        "Scapula: Shoulder blades",
        "Sternum: Bone at the front and center of the chest"
      ],
      "Irregular Bones": [
        "Mandible: Jaw bone",
        "Sacrum: Triangular bone just below the lumbar spine",
        "Vertebrae: Houses the spinal canal"
      ],
      "Sesamoid Bones": [
        "Patella: Knee cap bone",
        "Pisiform: Small rounded carpal bones situated where the palm of the hand meets the outer edge of the wrist"
      ],
      "Pneumatic Bones": [
        "Ethmoid: Square bone at the root of the nose",
        "Maxilla: Forms the upper jaw",
        "Sphenoid: Compound bone that forms the base of the cranium"
      ]
    },

    "Cells": {////I think this should be in the popups... I think even 

      "Mesoderm": [
        "Mesoderm cell: The middle layer of the cell between ectoderm and endoderm.",
        "Metabolism cells: Life sustaining chemical transformations within the cells.",
        "Storage cells: A cell whose energy can be renewed by passing a current through it in the direction opposite to that of the flow of current generated by the cell.",
        "Barrier function cells: Forms a barrier between the body and the environment within that part of the body.",
        "Extracellular matrix cells: Fills the spaces between cells and binds cells and tissues together.",
        "Contractile cells: The smooth muscle cells of the heart.",
        "Blood system cells: Key part of the body's immune system.",
        "Immune system cells: Protects us from infections and diseases.",
        "Germs cells: A reproductive system cell.",
        "Nurse cells: Reside in the bone marrow and assist in the development of red blood cells.",
        "Interstitial cells: Any cell that lies between other cells.",

      ],

    },
    "Actions": [
      "Change diet",
      "Change how I am treated",
      "Change living conditions",
      "Consider my life purpose",
      "Detoxify – emotionally/physically",
      "Do a clearing on the house",
      "Environmental changes are needed",
      "Have my health as a priority",
      "Learn",
      "Make changes",
      "Need more adventures",
      "Need more excitement",
      "Need more indoor time",
      "Need more outdoor time",
      "Need less stress",
      "Need time in nature",
      "Play more",
      "Respect my feelings",
      "Socialize more",
      "Take into consideration my feelings",
      "Want a new home",
      "Want a new job",
      "Want attention",
      "Want to be around kids",
      "Want to be heard",
      "Want to learn",
      "Want more attention",
      "Want my food bowls cleaned",
      "Want my litter box cleaned more",
      "Want my own litter box",
      "Want to socialize more",
      "Want to be around other animals",
      "Want to be around other humans",
      "Want more respect for my feelings",
      "Want more adventures",
      "Want more excitement",
      "I have unique needs to me that I need honored",
      "I need to feel safe",
      "I want my own space",
    ],
    "Emotional": {

      "Happy": [
        "Alive",
        "Appreciated",
        "Cheerful",
        "Confident",
        "Content",
        "Delighted",
        "Determined",
        "Ecstatic",
        "Energized",
        "Encouraged",
        "Enthusiastic",
        "Excited",
        "Exuberant",
        "Fortunate",
        "Fulfilled",
        "Glad",
        "Good",
        "Grateful",
        "Hopeful",
        "Jubilant",
        "Joyful",
        "Justified",
        "Loved",
        "Marvelous",
        "Optimistic",
        "Peaceful",
        "Pleased",
        "Proud",
        "Relaxed",
        "Relieved",
        "Resolved",
        "Respected",
        "Satisfied",
        "Terrific",
        "Thrilled",
        "Tranquil",
        "Valued"
      ],
      "Sad": [

        "Alienated",
        "Ashamed",
        "Burdened",
        "Condemned",
        "Crushed",
        "Dejected",
        "Demoralized",
        "Depressed",
        "Deserted",
        "Despised",
        "Dismal",
        "Disappointed",
        "Discarded",
        "Discouraged",
        "Disgraced",
        "Disheartened",
        "Disillusioned",
        "Disrespected",
        "Distanced",
        "Distraught",
        "Drained",
        "Empty",
        "Exhausted",
        "Grievous",
        "Helpless",
        "Hopeless",
        "Humbled",
        "Inadequate",
        "Islanded",
        "Isolated",
        "Lonely",
        "Miserable",
        "Mournful",
        "Neglected",
        "Pitiful",
        "Regretful",
        "Rejected",
        "Resigned",
        "Sorrowful",
        "Terrible",
        "Uncared for",
        "Unloved",
        "Unwanted",
        "Upset",
        "Worthless",
        "Wounded"
      ],
      "Angry": [
        "Aggravated",
        "Agitated",
        "Anguished",
        "Annoyed",
        "Betrayed",
        "Cheated",
        "Coerced",
        "Controlled",
        "Deceived",
        "Disgusted",
        "Displeased",
        "Dismayed",
        "Dominate",
        "Enraged",
        "Exasperated",
        "Exploited",
        "Frustrated",
        "Fuming",
        "Furious",
        "Harassed",
        "Hateful",
        "Hostile",
        "Humiliated",
        "Irritated",
        "Mad",
        "Outraged",
        "Patronized",
        "Peeved",
        "Perturbed",
        "Pissed off",
        "Provoked",
        "Repulsed",
        "Resentful",
        "Ridiculed",
        "Sabotaged",
        "Seething",
        "Smothered",
        "Spiteful",
        "Stifled",
        "Strangled",
        "Throttled",
        "Uptight",
        "Used",
        "Vengeful",
        "Vindictive"

      ],
      "Scared": [

        "Afraid",
        "Alarmed",
        "Anxious",
        "Appalled",
        "Apprehensive",
        "Awed",
        "Concerned",
        "Defensive",
        "Desperate",
        "Doubtful",
        "Frantic",
        "Full of dread",
        "Guarded",
        "Horrified",
        "Impatient",
        "Insecure",
        "Intimidated",
        "Nervous",
        "Overwhelmed",
        "Panic",
        "Panicky",
        "Petrified",
        "Perplexed",
        "Reluctant",
        "Shaken",
        "Shocked",
        "Shy",
        "Skeptical",
        "Startled",
        "Stunned",
        "Suspicious",
        "Swamped",
        "Terrified",
        "Threatened",
        "Timid",
        "Tense",
        "Tormented",
        "Uneasy",
        "Unsure",
        "Vulnerable"
      ],
      "Confused": [
        "Ambivalent",
        "Awkward",
        "Baffled",
        "Bewildered",
        "Bothered",
        "Constricted",
        "Directionless",
        "Disorganized",
        "Distracted",
        "Doubtful",
        "Flustered",
        "Foggy",
        "Hesitant",
        "Immobilized",
        "Misunderstood",
        "Perplexed",
        "Puzzled",
        "Stagnant",
        "Surprised",
        "Torn",
        "Troubled",
        "Trapped",
        "Uncertain",
        "Undecided",
        "Uncomfortable",
        "Unsettled",
        "Unsure"

      ],

    },
    "Reprod.": {
      "Female": [
        "Bartholin's Glands",
        "Cervix",
        "Clitoris",
        "Divine Feminine Energy",
        "Eggs",
        "Estrogen: Low/High Levels",
        "Fallopian Tubes: Left/Right/Both",
        "Follicle Stimulating Hormone: (FSH) Low/High",
        "Heat Cycle",
        "Labia Majora",
        "Labia Minora",
        "Leutinizing Hormone: (LH) Low/High",
        "Ovaries: Left/Right/Both",
        "Pregnancy",
        "Progesterone: Low/High Levels",
        "Uterus",
        "Vagina"
      ],
      "Male": [
        "Bladder",
        "Bulbourethral Gland",
        "Divine Masculine Energy",
        "Ejaculatory Ducts",
        "Epididymis",
        "Follicle Stimulating Hormone – (FSH) Low/High",
        "Leutinizing Hormone – (LH) Low/High",
        "Penis",
        "Prostate",
        "Scrotum",
        "Seminole Vessels",
        "Sperm",
        "Testicle – Left/Right/Both",
        "Testosterone – Low/High levels",
        "Urethra",
        "Vas Deferens"
      ],
    },

  }
}