import { TextPageMapType } from './types';
import React from 'react';
import FreedomTextPage from '../components/TextPage/FreedomTextPage';
import OriginTextPage from '../components/TextPage/OriginTextPage';
import KarmaTextPage from '../components/TextPage/KarmaTextPage';
import SpiritTextPage from '../components/TextPage/SpiritTextPage';
import NatureNutrientsPage from '../components/MultiChartPage/NatureNutrients';
import RelationshipsPage from '../components/MultiChartPage/Relationships';
import FengShuiGrid from '../components/MultiChartPage/BaguaAreaMap';
import BodyClimatePage from '../components/MultiChartPage/BodyClimate';
import MeasurementsServingsPage from '../components/MultiChartPage/MeasurementsServings';
import TimeChartsPage from '../components/MultiChartPage/TimeCharts';
import ExercisePage from '../components/MultiChartPage/Exercise';
import ECSPage from '../components/MultiChartPage/ECS';

const CustomPageMap: TextPageMapType = {
    "Freedom": {
        "Directions": FreedomTextPage,
    },
    "Origin": {
        "Directions": OriginTextPage,
    },
    "Karma": {
        "Directions": KarmaTextPage,
    },
    "Spirit": {
        "Directions": SpiritTextPage,
    },
    "Nature": {
        "Crop, Plant & Tree Issues": {
            "Nutrients": NatureNutrientsPage,
        },
    },
    "Soul": {
        "Relationships": RelationshipsPage,
    },
    "Home": {
        "Bagua Areas": {
            "Bagua Map": FengShuiGrid,
        }
    },
    "Business": {
        "Bagua Areas": {
            "Bagua Map": FengShuiGrid,
        }
    },
    "Nutrition": {
        "Body Climate": BodyClimatePage,
        "Measure/Servings": MeasurementsServingsPage,
        "Time Charts": TimeChartsPage,
        "Exercise": ExercisePage,
    },
    "Trauma Animal": {
        "Systems": {
            "ECS": ECSPage,
        }
    },
    "Trauma People": {
        "Systems": {
            "ECS": ECSPage,
        }
    },

};

export const GetCustomPage = (
    module: string | null | undefined,
    item: string | null | undefined,
    subitem: string | null | undefined,
    detail: string | null | undefined
) => {
    if (module && CustomPageMap[module]) {
        if (typeof CustomPageMap[module] === 'function') {
            if (!item && !subitem && !detail) {
                return CustomPageMap[module] as React.ComponentType<any>;
            }
            else {
                return null;
            }
        }

        if (typeof CustomPageMap[module] === 'object') {
            const moduleMap = CustomPageMap[module] as { [key: string]: React.ComponentType<any> | { [key: string]: React.ComponentType<any> | { [key: string]: React.ComponentType<any> } } };

            if (item && moduleMap[item]) {
                if (typeof moduleMap[item] === 'function') {
                    return moduleMap[item] as React.ComponentType<any>;
                } else if (typeof moduleMap[item] === 'object') {
                    const itemMap = moduleMap[item] as { [key: string]: React.ComponentType<any> | { [key: string]: React.ComponentType<any> } };
                    if (subitem && itemMap[subitem]) {
                        if (typeof itemMap[subitem] === 'function') {
                            return itemMap[subitem] as React.ComponentType<any>;
                        } else if (typeof itemMap[subitem] === 'object') {
                            const subitemMap = itemMap[subitem] as { [key: string]: React.ComponentType<any> };
                            if (detail && subitemMap[detail]) {
                                return subitemMap[detail] as React.ComponentType<any>;
                            }
                        }
                    }
                }
            }
        }
    }
    return null;
};

export default CustomPageMap;