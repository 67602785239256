import { TraumaAnimalPopUps } from './popups';
import { ChartPagesType } from '../types'

export const TraumaAnimalCharts: ChartPagesType = {
  "Trauma Animal": {
    chart: [
      { "id": 0, "label": "Angels", "value": 1, "color": "#380004" },
      { id: 1, label: 'Frequency', value: 1, color: '#571B1E' },
      { "id": 2, "label": "Trauma", "value": 1, "color": "#8A2528" },
      { "id": 3, "label": "Issues", "value": 1, "color": "#C3102F" },
      { "id": 4, "label": "Syncing", "value": 1, "color": "#E51A23" },
      { "id": 5, "label": "Body Imbalance", "value": 1, "color": "#F25B21" },
      { "id": 6, "label": "Energy Fields", "value": 1, "color": "#F7971D" },
      { "id": 7, "label": "Chakras", "value": 1, "color": "#EFB211" },
      { "id": 8, "label": "Blood", "value": 1, "color": "#FFE616" },
      { "id": 9, "label": "Meridians", "value": 1, "color": "#C1D72F" },
      { "id": 10, "label": "Body Area", "value": 1, "color": "#8BC63E" },
      { "id": 11, "label": "Systems", "value": 1, "color": "#46AA42" },
      { "id": 12, "label": "Emotional", "value": 1, "color": "#357B2B" },
      { "id": 13, "label": "Body Quad.", "value": 1, "color": "#00653A" },
      { "id": 14, "label": "Connectors", "value": 1, "color": "#006b6E" },
      { "id": 15, "label": "Bones", "value": 1, "color": "#0083A9" },
      { "id": 16, "label": "Cells", "value": 1, "color": "#0077C0" },
      { "id": 17, "label": "Reprod.", "value": 1, "color": "#135CAB" },
      { "id": 18, "label": "Colors", "value": 1, "color": "#263692" },
      { "id": 19, "label": "Actions", "value": 1, "color": "#8C63AA" },
      { "id": 20, "label": "Clearing", "value": 1, "color": "#692C91" },
      // { "id": 20, "label": "Frequency", "value": 1, "color": "#6C1F7D" },
      { "id": 21, "label": "Origination", "value": 1, "color": "#862074" },
      { "id": 22, "label": "Other Module", "value": 1, "color": "#535249", onClickNav: "/?module=All Modules" },

    ],

    parent: "All Modules",
    level: 1,
    subPages: {
      "Angels": {
        chart: [
          { id: 0, label: 'Arcturians', value: 1, color: '#380004', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.ArcturianEmmissionaries },
          { id: 1, label: 'Raphael', value: 1, color: '#bd9395', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.Raphael },
          { id: 2, label: 'Gabriel', value: 1, color: '#380004', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.Gabriel },
          { id: 3, label: 'Michael', value: 1, color: '#bd9395', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.Michael },
          { id: 4, label: 'Jophiel', value: 1, color: '#380004', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.Jophiel },
          { id: 5, label: 'Chamuel', value: 1, color: '#bd9395', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.Chamuel },
          { id: 6, label: 'Uriel', value: 1, color: '#380004', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.Uriel },
          { id: 7, label: 'Zadkiel', value: 1, color: '#bd9395', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.Zadkiel },
          { id: 8, label: 'Spirit Guide', value: 1, color: '#380004', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.SpiritGuide },
          { id: 9, label: 'Metatron', value: 1, color: '#bd9395', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.Metatron },
          { id: 10, label: 'Jesus Christ', value: 1, color: '#380004', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.JesusChrist },
          { id: 11, label: 'Animal Companion', value: 1, color: '#bd9395', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.AnimalCompanion },
          { id: 12, label: 'Other Helper', value: 1, color: '#380004', popupContent: TraumaAnimalPopUps.TraumaAnimal.Angels.OtherHelper },
        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },

      "Trauma": {
        chart: [
          { id: 0, label: 'Chromosome Abn.', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.ChromosomeAbnormality },
          { id: 1, label: 'Electro. Smog', value: 1, color: '#DFC4C5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.ElectromagneticSmog },
          { id: 2, label: 'Diet Deficiencies', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.DietDeficiencies },
          { id: 3, label: 'Group Consc.', value: 1, color: '#DFC4C5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.GroupConciousness },
          { id: 4, label: 'Emotions', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Emotions },
          { id: 5, label: 'PTSD', value: 1, color: '#DFC4C5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.PTSD },
          { id: 6, label: 'Past Life', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.PastLive },
          { id: 7, label: 'Genetics', value: 1, color: '#DFC4C5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Genetics },
          { id: 8, label: 'Syncing', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Syncing },
          { id: 9, label: 'Injury', value: 1, color: '#DFC4C5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Injury },
          { id: 10, label: 'Chemicals', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Chemicals },
          { id: 11, label: 'Experiences', value: 1, color: '#DFC4C5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Experiences },
          { id: 12, label: 'Relationships', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Relationships },
          { id: 13, label: 'Surgery', value: 1, color: '#DFC4C5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Surgery },
          { id: 14, label: 'Vaccinations', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Vaccinations },
          { id: 15, label: 'Cording', value: 1, color: '#DFC4C5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Cording },
          { id: 16, label: 'GMO Foods', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.GMOFoods },
          { id: 17, label: 'Programming', value: 1, color: '#DFC4C5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.Programming },
          { id: 18, label: 'Env. Toxins', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.EnvironmentalToxins },
          { id: 19, label: 'Env. Conditions', value: 1, color: '#DFC4C5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.EnvironmentalConditions },
          { id: 20, label: 'Consciousness', value: 1, color: '#8A2528', popupContent: TraumaAnimalPopUps.TraumaAnimal.Trauma.SpeciesConsciousness },
        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },

      "Issues": {
        chart: [

          { id: 0, label: 'Relational Env.', value: 1, color: '#C3102F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.RelationalEnvironment },
          { id: 1, label: 'Spiritual Env.', value: 1, color: '#D1C2C2', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.SpiritualEnvironment },
          { id: 2, label: 'RNA', value: 1, color: '#C3102F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.RNA },
          { id: 3, label: 'Genetics', value: 1, color: '#D1C2C2', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.Genetics },
          { id: 4, label: 'Coping Skills', value: 1, color: '#C3102F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.CopingSKills },
          { id: 5, label: 'Dietary', value: 1, color: '#D1C2C2', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.Dietary },
          { id: 6, label: 'Soul Unrest', value: 1, color: '#C3102F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.SoulUnrest },
          { id: 7, label: 'DNA', value: 1, color: '#D1C2C2', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.DNA },
          { id: 8, label: 'Chromosomal', value: 1, color: '#C3102F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.Chromosomal },
          { id: 9, label: 'Situational', value: 1, color: '#D1C2C2', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.Situational },
          { id: 10, label: 'Chemical', value: 1, color: '#C3102F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.Chemical },
          { id: 11, label: 'Body Imbalance', value: 1, color: '#D1C2C2', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.BodyImbalance },
          { id: 12, label: 'Physical Env.', value: 1, color: '#C3102F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Issues.PhysicalEnvironment },
        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },


      "Syncing": {
        chart: [
          { id: 0, label: 'Deceased Companion', value: 1, color: '#E51A23', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.Deceased },
          { id: 1, label: 'Like Species Group', value: 1, color: '#F8C1C4', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.LikeSpeciesGroup },
          { id: 2, label: 'Human\'s Roommate', value: 1, color: '#E51A23', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.HumansRoomate },
          { id: 3, label: 'Human\'s Friend', value: 1, color: '#F8C1C4', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.HumansFriend },
          { id: 4, label: 'Animal Companion', value: 1, color: '#E51A23', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.AnimalCompanion },
          { id: 5, label: 'Caretaker', value: 1, color: '#F8C1C4', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.Caretaker },
          { id: 6, label: 'Group Mentality', value: 1, color: '#E51A23', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.GroupMentality },
          { id: 7, label: 'Child - Human', value: 1, color: '#F8C1C4', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.ChildHuman },
          { id: 8, label: 'Environment', value: 1, color: '#E51A23', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.Environment },
          { id: 9, label: 'Foster Home', value: 1, color: '#F8C1C4', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.FosterHome },
          { id: 10, label: 'Foster Person', value: 1, color: '#E51A23', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.FosterPerson },
          { id: 11, label: 'Shelter/Rescue', value: 1, color: '#F8C1C4', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.ShelterRescue },
          { id: 12, label: 'Adult - Human', value: 1, color: '#E51A23', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.AdultHuman },
          { id: 13, label: 'Human\'s Partner', value: 1, color: '#F8C1C4', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.HumansPartner },
          { id: 14, label: 'Human\'s Health', value: 1, color: '#E51A23', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.HumansHealth },
          { id: 15, label: 'Mental Patterns', value: 1, color: '#F8C1C4', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.HumansMentalPattern },
          { id: 16, label: 'Home Energy', value: 1, color: '#E51A23', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.HomeEnergy },
          { id: 17, label: 'Something Not Listed', value: 1, color: '#F8C1C4', popupContent: TraumaAnimalPopUps.TraumaAnimal.Syncing.SomethingElse },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },

      "Body Imbalance": {
        chart: [
          { id: 0, label: 'Virus', value: 1, color: '#F25B21' },
          { id: 1, label: 'Bacteria', value: 1, color: '#FBD3C3' },
          { id: 2, label: 'Parasites', value: 1, color: '#F25B21' },
          { id: 3, label: 'Fungus', value: 1, color: '#FBD3C3' },
          { id: 4, label: 'Disease', value: 1, color: '#F25B21' },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },

      "Energy Fields": {
        chart: [
          { id: 0, label: 'Etheric Field #1', value: 1, color: '#F7971D', popupContent: TraumaAnimalPopUps.TraumaAnimal.EnergyFields.EF1 },
          { id: 1, label: 'Etheric Field #2', value: 1, color: '#FCE3C2', popupContent: TraumaAnimalPopUps.TraumaAnimal.EnergyFields.EF2 },
          { id: 2, label: 'Emotional', value: 1, color: '#F7971D', popupContent: TraumaAnimalPopUps.TraumaAnimal.EnergyFields.Emotional },
          { id: 3, label: 'Mental #1', value: 1, color: '#FCE3C2', popupContent: TraumaAnimalPopUps.TraumaAnimal.EnergyFields.M1 },
          { id: 4, label: 'Mental #2', value: 1, color: '#F7971D', popupContent: TraumaAnimalPopUps.TraumaAnimal.EnergyFields.M2 },
          { id: 5, label: 'Spiritual #1', value: 1, color: '#FCE3C2', popupContent: TraumaAnimalPopUps.TraumaAnimal.EnergyFields.S1 },
          { id: 6, label: 'Spiritual #2', value: 1, color: '#F7971D', popupContent: TraumaAnimalPopUps.TraumaAnimal.EnergyFields.S2 },
          { id: 7, label: 'Spiritual #3', value: 1, color: '#FCE3C2', popupContent: TraumaAnimalPopUps.TraumaAnimal.EnergyFields.S3 },
          { id: 8, label: 'Transpersonal', value: 1, color: '#F7971D', popupContent: TraumaAnimalPopUps.TraumaAnimal.EnergyFields.Transpersonal },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },

      "Chakras": {
        chart: [

          { id: 0, label: 'Root Chakra', value: 1, color: '#EFB211', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.Root },
          { id: 1, label: 'Sacral Chakra', value: 1, color: '#FAEABF', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.Sacral },
          { id: 2, label: 'Solar Plexus', value: 1, color: '#EFB211', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.Solar },
          { id: 3, label: 'Heart Chakra', value: 1, color: '#FAEABF', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.Heart },
          { id: 4, label: 'Throat Chakra', value: 1, color: '#EFB211', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.Throat },
          { id: 5, label: 'Third Eye Chakra', value: 1, color: '#FAEABF', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.ThirdEye },
          { id: 6, label: 'Brachial Chakra', value: 1, color: '#EFB211', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.Brachial },
          { id: 7, label: 'Minor Chakra', value: 1, color: '#FAEABF', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.Minor },
          { id: 8, label: 'Bud Chakras', value: 1, color: '#EFB211', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.Bud },
          { id: 9, label: 'Minor Sensory Chakras', value: 1, color: '#FAEABF', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.MinorSensory },
          { id: 10, label: 'Chakra Not Listed', value: 1, color: '#EFB211', popupContent: TraumaAnimalPopUps.TraumaAnimal.Chakras.NoInfo },
        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },


      "Blood": {
        chart: [
          { id: 0, label: 'White Cell Antibodies', value: 1, color: '#FFE616', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.WhiteCellAntibodies },
          { id: 1, label: 'Carbon Dioxide', value: 1, color: '#FFF8C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.CarbonDioxide },
          { id: 2, label: 'White Cells', value: 1, color: '#FFE616' },
          { id: 3, label: 'Red Cells', value: 1, color: '#FFF8C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.RedCells },
          { id: 4, label: 'Nitrogen', value: 1, color: '#FFE616', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Nitrogen },
          { id: 5, label: 'Hormones', value: 1, color: '#FFF8C0' },
          { id: 6, label: 'Proteins', value: 1, color: '#FFE616', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Proteins },
          { id: 7, label: 'Plasma', value: 1, color: '#FFF8C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Plasma },
          { id: 8, label: 'Oxygen', value: 1, color: '#FFE616', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Oxygen },
          { id: 9, label: 'Stem Cells', value: 1, color: '#FFF8C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.StemCells },
          { id: 10, label: 'Platelets', value: 1, color: '#FFE616', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Platelets },
          { id: 11, label: 'Hemoglobin', value: 1, color: '#FFF8C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Hemoglobin },
          { id: 12, label: 'Fat Globules', value: 1, color: '#FFE616', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Fat },
          { id: 13, label: 'Carbohydrates', value: 1, color: '#FFF8C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Carbs },
          { id: 14, label: 'Blood Comp.', value: 1, color: '#FFE616' },
          { id: 15, label: 'Comp. Blood Cnt.', value: 1, color: '#FFF8C0' },
          { id: 16, label: 'Erthrocytes', value: 1, color: '#FFE616', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Erthrocytes },
          { id: 17, label: 'Hemocytes', value: 1, color: '#FFF8C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Hemocytes },
          { id: 18, label: 'Hemolymph', value: 1, color: '#FFE616', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Hemolymph },
          { id: 19, label: 'Hemocyanin', value: 1, color: '#FFF8C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Blood.Hemocyanin },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },


      "Meridians": {
        chart: [
          { id: 0, label: 'Spleen (Pancreas)', value: 1, color: '#C1D72F' },
          { id: 1, label: 'Large Intestine', value: 1, color: '#EEF4C7', popupContent: TraumaAnimalPopUps.TraumaAnimal.Meridians.LargeIntestine },
          { id: 2, label: 'Stomach', value: 1, color: '#C1D72F' },
          { id: 3, label: 'Lung', value: 1, color: '#EEF4C7'},
          { id: 4, label: 'Heart', value: 1, color: '#C1D72F'},
          { id: 5, label: 'Sm. Intestine', value: 1, color: '#EEF4C7', popupContent: TraumaAnimalPopUps.TraumaAnimal.Meridians.SmIntestine },
          { id: 6, label: 'Bladder', value: 1, color: '#C1D72F' },
          { id: 7, label: 'Kidney', value: 1, color: '#EEF4C7'},
          { id: 8, label: 'Heart Governor', value: 1, color: '#C1D72F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Meridians.HeartGovernor },
          { id: 9, label: 'Triple Heater', value: 1, color: '#EEF4C7' },
          { id: 10, label: 'Gall Bladder', value: 1, color: '#C1D72F'},
          { id: 11, label: 'Liver', value: 1, color: '#EEF4C7'},
          { id: 12, label: 'Pericardium', value: 1, color: '#C1D72F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Meridians.Paricardium },
          { id: 13, label: 'Governing Vessel', value: 1, color: '#EEF4C7' },

          { id: 14, label: 'Multiple Meridians', value: 1, color: '#C1D72F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Meridians.Multiple },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },

      "Body Area": {
        chart: [
          { id: 0, label: 'Skin/Fur/Feathers', value: 1, color: '#8BC63E', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.SkinFurFeatures },
          { id: 1, label: 'Neurological', value: 1, color: '#E2F0CF', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.Neurological },
          { id: 2, label: 'Endocrine', value: 1, color: '#8BC63E' },
          { id: 3, label: 'Eye(s)', value: 1, color: '#E2F0CF', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.Eyes },
          { id: 4, label: 'Ear(s)', value: 1, color: '#8BC63E', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.Ears },
          { id: 5, label: 'Organs', value: 1, color: '#E2F0CF' },
          { id: 6, label: 'Lymph', value: 1, color: '#8BC63E', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.Lymph },
          { id: 7, label: 'Brain', value: 1, color: '#E2F0CF'},
          { id: 8, label: 'Colon', value: 1, color: '#8BC63E', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.Colon },
          { id: 9, label: 'Muscle', value: 1, color: '#E2F0CF', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.Muscles },
          { id: 10, label: 'Fascia', value: 1, color: '#8BC63E', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.Fascia },
          { id: 11, label: 'Tendon(s)', value: 1, color: '#E2F0CF', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.Tendons },
          { id: 12, label: 'Bone(s)', value: 1, color: '#8BC63E', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.Bones },
          { id: 13, label: 'Body Part', value: 1, color: '#E2F0CF', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.BodyArea },
          { id: 14, label: 'Circulatory System', value: 1, color: '#8BC63E', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyArea.CirculatorySystem },
        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },


      "Systems": {
        chart: [

          { id: 0, label: 'Integumentary', value: 1, color: '#46AA42', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Integumentary },
          { id: 1, label: 'Urinary', value: 1, color: '#CDE8CC', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Urinary },
          { id: 2, label: 'Reproductive', value: 1, color: '#46AA42', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Reproductive },
          { id: 3, label: 'Endocrine', value: 1, color: '#CDE8CC', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Endocrine },
          { id: 4, label: 'Digestive', value: 1, color: '#46AA42', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Digestive },
          { id: 5, label: 'Lymphatic', value: 1, color: '#CDE8CC', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Lymphatic },
          { id: 6, label: 'Nervous', value: 1, color: '#46AA42', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Nervous },
          { id: 7, label: 'Cardiovascular', value: 1, color: '#CDE8CC', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Cardiovascular },
          { id: 8, label: 'Immune', value: 1, color: '#46AA42', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Immune },
          { id: 9, label: 'Respiratory', value: 1, color: '#CDE8CC', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Respiratory },
          { id: 10, label: 'Skeletal', value: 1, color: '#46AA42', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Skeletal },
          { id: 11, label: 'Muscular', value: 1, color: '#CDE8CC', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.Muscular },
          { id: 12, label: 'SRS', value: 1, color: '#46AA42', popupContent: TraumaAnimalPopUps.TraumaAnimal.Systems.SRS },
          { id: 13, label: 'ECS', value: 1, color: '#CDE8CC' },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {
        }
      },

      "Emotional": {
        chart: [

          { id: 0, label: 'Happy', value: 1, color: '#357B2B' },
          { id: 1, label: 'Sad', value: 1, color: '#CDDECA' },
          { id: 2, label: 'Angry', value: 1, color: '#357B2B' },
          { id: 3, label: 'Scared', value: 1, color: '#CDDECA' },
          { id: 4, label: 'Confused', value: 1, color: '#357B2B' },
          { id: 5, label: 'Bored', value: 1, color: '#CDDECA', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.Bored },
          { id: 6, label: 'Attn. Issue', value: 1, color: '#357B2B', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.AttnIssue },
          { id: 7, label: 'Need Space', value: 1, color: '#CDDECA', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.NeedSpace },
          { id: 8, label: 'Low Self Esteem', value: 1, color: '#357B2B', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.LSE },
          { id: 9, label: 'Grieving', value: 1, color: '#CDDECA', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.Grieving },
          { id: 10, label: 'Worried', value: 1, color: '#357B2B', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.Worried },
          { id: 11, label: 'Jealous', value: 1, color: '#CDDECA', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.Jealous },
          { id: 12, label: 'Preoccupied', value: 1, color: '#357B2B', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.Preoccupied },
          { id: 13, label: 'Shame', value: 1, color: '#CDDECA', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.Shame },
          { id: 14, label: 'W/out Direction', value: 1, color: '#357B2B', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.WithoutDirection },
          { id: 15, label: 'Need To Belong', value: 1, color: '#CDDECA', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.NeedToBelong },
          { id: 16, label: 'Taken For Granted', value: 1, color: '#357B2B', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.TakenForGranted },
          { id: 17, label: 'Overwhelmed', value: 1, color: '#CDDECA', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.Overwhelmed },
          { id: 18, label: 'Emotional Sponging', value: 1, color: '#357B2B', popupContent: TraumaAnimalPopUps.TraumaAnimal.Emotional.EmotionalSponging },
        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },

      "Body Quad.": {
        chart: [

          { id: 0, label: '#1 Head', value: 1, color: '#00653A', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyQuadrants.Head },
          { id: 1, label: '#2 Chest', value: 1, color: '#BBD5CA', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyQuadrants.Chest },
          { id: 2, label: '#3 Torso', value: 1, color: '#00653A', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyQuadrants.Torso },
          { id: 3, label: '#4 Hind End', value: 1, color: '#BBD5CA', popupContent: TraumaAnimalPopUps.TraumaAnimal.BodyQuadrants.HindEnd },
        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },


      "Connectors": {
        chart: [
          { id: 0, label: 'Fascia', value: 1, color: '#006B6E', popupContent: TraumaAnimalPopUps.TraumaAnimal.Connectors.Fascia },
          { id: 1, label: 'Muscles', value: 1, color: '#BBD7D8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Connectors.Muscles },
          { id: 2, label: 'Tendons', value: 1, color: '#006B6E', popupContent: TraumaAnimalPopUps.TraumaAnimal.Connectors.Tendons },
          { id: 3, label: 'Nerves', value: 1, color: '#BBD7D8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Connectors.Nerves },
          { id: 4, label: 'Ligaments', value: 1, color: '#006B6E', popupContent: TraumaAnimalPopUps.TraumaAnimal.Connectors.Ligaments },
          { id: 5, label: 'Bones', value: 1, color: '#BBD7D8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Connectors.Bones },
          { id: 6, label: 'Joints', value: 1, color: '#006B6E', popupContent: TraumaAnimalPopUps.TraumaAnimal.Connectors.Joints },
          { id: 7, label: 'Skin', value: 1, color: '#BBD7D8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Connectors.Skin },
          { id: 8, label: 'Cartilage', value: 1, color: '#006B6E', popupContent: TraumaAnimalPopUps.TraumaAnimal.Connectors.Cartilage },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },
      "Bones": {
        chart: [

          { id: 0, label: 'Long Bones', value: 1, color: '#0083A9' },
          { id: 1, label: 'Short Bones', value: 1, color: '#BBDDE8' },
          { id: 2, label: 'Flat Bones', value: 1, color: '#0083A9' },
          { id: 3, label: 'Irregular Bones', value: 1, color: '#BBDDE8' },
          { id: 4, label: 'Sesamoid Bones', value: 1, color: '#0083A9' },
          { id: 5, label: 'Pneumatic Bones', value: 1, color: '#BBDDE8' },
        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },

      "Cells": {
        chart: [
          { id: 0, label: 'Nuclear Envelope', value: 1, color: '#0077C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.NuclearEnvelope },
          { id: 1, label: 'Cell Membrane', value: 1, color: '#BBDAEE', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.CellMembrane },
          { id: 2, label: 'Endoplasmic Reticulum', value: 1, color: '#0077C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.EndoplasmicReticulum },
          { id: 3, label: 'Mitochondria', value: 1, color: '#BBDAEE', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Mitochondria },
          { id: 4, label: 'Cytoskeleton', value: 1, color: '#0077C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Cytoskeleton },
          { id: 5, label: 'Eukaryote', value: 1, color: '#BBDAEE', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Eukaryote },
          { id: 6, label: 'Fetal Cell', value: 1, color: '#0077C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.FetalCell },
          { id: 7, label: 'Mesoderm', value: 1, color: '#BBDAEE' },
          { id: 8, label: 'Lysosomes', value: 1, color: '#0077C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Lysosomes },
          { id: 9, label: 'Cell', value: 1, color: '#BBDAEE', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Cell },
          { id: 10, label: 'Cytoplasm', value: 1, color: '#0077C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Cytoplasm },
          { id: 11, label: 'Nucleolus', value: 1, color: '#BBDAEE', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Nucleolus },
          { id: 12, label: 'Organelle', value: 1, color: '#0077C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Organelle },
          { id: 13, label: 'Prokaryote', value: 1, color: '#BBDAEE', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Prokaryote },
          { id: 14, label: 'Ribosome', value: 1, color: '#0077C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Ribosome },
          { id: 15, label: 'Vacuole', value: 1, color: '#BBDAEE', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Vacuole },
          { id: 16, label: 'Vesicle', value: 1, color: '#0077C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Vesicle },
          { id: 17, label: 'Endoderm', value: 1, color: '#BBDAEE', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Endoderm },
          { id: 18, label: 'Ectoderm', value: 1, color: '#0077C0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.Ectoderm },
          { id: 19, label: 'Golgi Apparatus', value: 1, color: '#BBDAEE', popupContent: TraumaAnimalPopUps.TraumaAnimal.Cells.GolgiApparatus },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },


      "Reprod.": {
        chart: [

          { id: 0, label: 'Female', value: 1, color: '#135CAB' },
          { id: 1, label: 'Male', value: 1, color: '#C0D3E8' }
        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },

      "Colors": {
        chart: [
          { id: 0, label: 'Red', value: 1, color: '#C3102F', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Red },
          { id: 1, label: 'Orange', value: 1, color: '#F48026', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Orange },
          { id: 2, label: 'Yellow', value: 1, color: '#F9EC33', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Yellow },
          { id: 3, label: 'Green', value: 1, color: '#46AA42', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Green },
          { id: 4, label: 'Blue', value: 1, color: '#263692', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Blue },
          { id: 5, label: 'Violet', value: 1, color: '#77278D', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Violet },
          { id: 6, label: 'Indigo', value: 1, color: '#4E2582', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Indigo },
          { id: 7, label: 'White', value: 1, color: '#f0f0f0', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.White },
          { id: 8, label: 'Silver', value: 1, color: '#A8A9AD', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Silver },
          { id: 9, label: 'Gold', value: 1, color: '#D3881B', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Gold },
          { id: 10, label: 'Black', value: 1, color: '#231F20', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Black },
          { id: 11, label: 'Rainbow', value: 1, color: '#ffd700', popupContent: TraumaAnimalPopUps.TraumaAnimal.Colors.Rainbow },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },

      "Clearing": {
        chart: [
          { id: 0, label: 'Frequency', value: 1, color: '#6C1F7D', popupContent: TraumaAnimalPopUps.TraumaAnimal.Clearing.Frequency },
          { id: 3, label: 'Call In An Angel', value: 1, color: '#D7C3DC', popupContent: TraumaAnimalPopUps.TraumaAnimal.Clearing.CallAngel },
          { id: 4, label: 'Write Your Intention', value: 1, color: '#6C1F7D', popupContent: TraumaAnimalPopUps.TraumaAnimal.Clearing.WriteIntention },
          { id: 6, label: 'Animal Message', value: 1, color: '#D7C3DC', popupContent: TraumaAnimalPopUps.TraumaAnimal.Clearing.Animal },
          { id: 7, label: 'Spirit Message', value: 1, color: '#6C1F7D', popupContent: TraumaAnimalPopUps.TraumaAnimal.Clearing.Spirit },
          { id: 8, label: 'Take Inspired Action', value: 1, color: '#D7C3DC', popupContent: TraumaAnimalPopUps.TraumaAnimal.Clearing.TakeInspriedAction },
          { id: 9, label: 'Clear Energy Field', value: 1, color: '#6C1F7D', popupContent: TraumaAnimalPopUps.TraumaAnimal.Clearing.ClearEnergyField },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },


      "Frequency": {
        chart: [
          { id: 0, label: '-100', value: 1, color: '#571B1E', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 1, label: '-90', value: 1, color: '#683234', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 2, label: '-80', value: 1, color: '#78484B', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 3, label: '-70', value: 1, color: '#895F61', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 4, label: '-60', value: 1, color: '#9A7678', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 5, label: '-50', value: 1, color: '#AB8D8E', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 6, label: '-40', value: 1, color: '#BCA4A5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 7, label: '-30', value: 1, color: '#CBB', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 8, label: '-20', value: 1, color: '#DED2D2', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 9, label: '-10', value: 1, color: '#EEE8E8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 10, label: '0', value: 1, color: '#EEE8E8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 11, label: '10', value: 1, color: '#EEE8E8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 12, label: '20', value: 1, color: '#DED2D2', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 13, label: '30', value: 1, color: '#CBB', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 14, label: '40', value: 1, color: '#BCA4A5', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 15, label: '50', value: 1, color: '#AB8D8E', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 16, label: '60', value: 1, color: '#9A7678', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 17, label: '70', value: 1, color: '#895F61', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 18, label: '80', value: 1, color: '#78484B', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },
          { id: 19, label: '90', value: 1, color: '#683234', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency }, // Note: This appears to be a repeat and might be intended for '90'.
          { id: 20, label: '100', value: 1, color: '#571B1E', popupContent: TraumaAnimalPopUps.TraumaAnimal.Frequency.Frequency },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },


      "Origination": {
        chart: [
          { id: 0, label: '1', value: 1, color: '#896531', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },
          { id: 1, label: '2', value: 1, color: '#DFD5C8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },
          { id: 2, label: '3', value: 1, color: '#896531', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },
          { id: 3, label: '4', value: 1, color: '#DFD5C8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },
          { id: 4, label: '5', value: 1, color: '#896531', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },
          { id: 5, label: '6', value: 1, color: '#DFD5C8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },
          { id: 6, label: '7', value: 1, color: '#896531', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },
          { id: 7, label: '8', value: 1, color: '#DFD5C8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },
          { id: 8, label: '9', value: 1, color: '#896531', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },
          { id: 9, label: '10', value: 1, color: '#DFD5C8', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },
          { id: 10, label: 'More Than 10', value: 1, color: '#896531', popupContent: TraumaAnimalPopUps.TraumaAnimal.Origination.Origination },

        ],
        parent: "TraumaAnimal",
        level: 2,
        subPages: {}
      },


    }
  }
}