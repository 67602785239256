
import { WordListMappingType, WordListPageTitle } from "../types";
import Typography from '@mui/material/Typography';


export const ToxicityWordTitle = () => {
  return (
    <Typography variant="h6" style={{ textAlign: 'center', padding: '10px' }}>
      🐾:Toxic to Dogs & Cats, 🐎:Toxic to Horses
    </Typography>);
}

export const ToxicityCaffeineWordTitle = () => {
  return (
    <>
      <Typography variant="h6" style={{ textAlign: 'center', marginTop: '5px' }}>
        🐾:Toxic to Dogs & Cats, 🐎:Toxic to Horses
      </Typography>
      <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: '-5px' }}>
        (Any caffeinated beverage or alcoholic beverage is toxic to all animals.)
      </Typography>
    </>);
}

export const NutritionWordTitleMap: WordListPageTitle = {
  "Nutrition": {
    "Protein": {
      "Animal Protein": <ToxicityWordTitle />,
      "Plant Protein": <ToxicityWordTitle />,
    },
    "Vegetables": <ToxicityWordTitle />,
    "Fruits": <ToxicityWordTitle />,
    "Grains": <ToxicityWordTitle />,
    "Dairy": <ToxicityWordTitle />,
    "Oils & Additives": <ToxicityWordTitle />,
    "Fats & Essential Fatty Acids": <ToxicityWordTitle />,
    "Sweeteners": <ToxicityWordTitle />,
    "Spices & Cooking": <ToxicityWordTitle />,
    "Medication": <ToxicityWordTitle />,
    "Beverages & Condiments": <ToxicityCaffeineWordTitle />,

  }
}

export const NutritionWordListMap: WordListMappingType = {
  "Nutrition": {
    "Protein": {
      "Animal Protein": [
        {
          title: "Alkaline", words: [
            "Cottage Cheese",
            "Whey Protein Powder",
            "Milk - Goats",
            "Cheese - Goats"
          ]
        },
        {
          title: "Acidic", words: [
            "Beef",
            "Shellfish",
            "Turkey",
            "Pork",
            "Lamb",
            "Veal",
            "Rabbit",
            "Venison",
            "Clams",
            "Sardines",
            "Sausage",
            "Organ Meats",
            "Mutton",
            "Lobster",
            "Mussels",
            "Oysters",
            "Carp",
            "Cod",
            "Fish",
            "Haddock",
            "Pike",
            "Salmon",
            "Tuna (Too Much)🐾",
          ]
        },
        {
          title: "Neutral", words: [
            "Chicken",
            "Eggs",
            "Whey",
            "Milk - Cow",
            "Bison",
            "Poultry",
            "Seafood",
            "Ham",
            "Liver - Beef/Pork/Chicken (Too Much)🐾",
            "Giblets",
            "Duck",
            "Goose",
            "Anchovies",
            "Frankfurters",
            "Trout",
            "Herring",
            "Mackerel",
            "Oysters",
            "Water Fowl",
            "Deer",
            "Cheese",
            "Yogurt",
            "Halibut",
            "Octopus",
            "Tilapia",
            "Shrimp",
            "Lutefisk",
            "Butter",
            "Cheese - Cow",
            "Cornish Hen",
            "Ostrich",
            "Partridge",
            "Pheasant",
            "Quail",
            "Scallops",
            "Shrimp",
            "Fat Trimmings🐾",
            "Cooked Bones🐾",
            "Bacon🐾"
          ],
        }],
      "Plant Protein": [
        {
          title: "Alkaline", words: [
            "Flax Seeds",
            "Almonds Nuts",
            "Brazil Nuts",
            "Chestnuts",
            "Tempeh",
            "Tofu",
            "Black-Eyed Peas",
            "Falafel",
            "Almond Milk",
            "Wheat Hay",
            "Millet"
          ]
        },
        {
          title: "Acidic", words: [
            "Lentils",
            "Cashew Nuts",
            "Aduki Beans",
            "Black Beans",
            "Chickpeas",
            "Garbanzo Beans",
            "Peas",
            "Kidney Beans",
            "Lentils",
            "Lima Beans",
            "Textured Vegetable Protein",
            "Soy Milk",
            "Rice Milk",
            "Wheat Germ",
            "Legumes",
            "White Beans",
            "Red Beans"
          ]
        },
        {
          title: "Neutral", words: [
            "Quinoa",
            "Spirulina",
            "Nutritional Yeast",
            "Hemp Seeds",
            "Chia Seeds",
            "Poppy Seeds",
            "Pumpkin Seeds",
            "Sesame Seeds (White)",
            "Sesame Seeds (Black)",
            "Sunflower Seeds",
            "Coconut",
            "Filberts",
            "Hazelnuts",
            "Macadamia 🐾",
            "Peanuts",
            "Pecans",
            "Pine Nuts",
            "Pistachios",
            "Walnuts",
            "Black-Eyed Beans",
            "Fava Beans",
            "Green Beans",
            "Mung Beans",
            "Navy Beans",
            "Pinto Beans",
            "Soybeans",
            "Split Peas",
            "Edamame",
            "Buckwheat",
            "Soy",
            "Seitan",
            "Peanut Butter",
            "Hummus",
            "Ezekiel Bread",
            "Beans",
            "Green Peas",
            "Soba Noodles",
            "Butter",
            "Alfalfa Hay",
            "Beet Pulp",
            "Bermuda Grass",
            "Coastal Grass",
            "Tifton Grass",
            "Brome Grass",
            "Orchard Grass",
            "Timothy Grass",
            "Fescure Grass",
            "Oat Hay",
            "Rice Bran",
            "Straw",
            "Soybean Hulls",
            "Wheat Bran",
            "Beet Pulp",
            "Wheat Middlings",
            "Canola Meal",
            "Flaxseed Meal",
            "Soybean Meal",
            "Sunflower Meal",
            "Plant Protein Shake",
            "Tahini"
          ],
        }]

    },
    //🐎
    // 🐾
    "Vegetables": [
      {
        title: "Alkaline", words: [
          "Okra",
          "Turnips",
          "Artichokes",
          "Avocado🐾🐎",
          "Green Beans",
          "Bean Sprouts",
          "Beets",
          "Celery",
          "Chili",
          "Broccoflower🐎",
          "Brussel Sprouts",
          "Cabbage🐎",
          "Cauliflower🐎",
          "Cucumbers",
          "Eggplant",
          "Garlic🐾🐎",
          "Onion🐾🐎",
          "Kohlrabi",
          "Potato (Raw Or Green)🐾🐎",
          "Iceberg Lettuce",
          "Mushrooms",
          "Lettuce",
          "Broccoli",
          "Dark Green Leafy Lettuce",
          "Kale",
          "Spinach",
          "Turnip Greens",
          "Watercress",
          "Beet Root",
          "Carrots",
          "Pumpkin",
          "Radish",
          "Red Peppers🐎",
          "Sweet Potatoes",
          "Tomatoes🐎",
          "Broccoli",
          "Dark Green Leafy Lettuce",
          "Kale",
          "Spinach",
          "Turnip Greens",
          "Watercress",
          "Black-Eyed Peas",
          "Field Peas",

          "Alfalfa Sprouts",
          "Beet Leaves",
          "Boston Lettuce",
          "Chard (Green)",
          "Chili Pepper",
          "Collard Greens",
          "Green Pepper🐎",

          "Lettuce - All Kinds",
          "Leeks",
          "Parsnip",
          "Rutabaga",
          "Seaweed",
          "Snow Peas",
          "Sugar Snap Peas",
          "Spirulina",
          "Sea Veggies",
          "Wheat Grass",
          "Wild Greens",
          "Alfalfa",
          "Dandelion Root",
          "Daikon",
          "Nori",
          "Reishi",
          "Shitake",
          "Umeboshi"
        ]
      },
      {
        title: "Acidic", words: [
          "Zucchini",
          "Black Beans",
          "Chickpeas (Garbanzo Beans)",
          "Kidney Beans",
          "Lentils",
          "Pinto Beans",
          "Corn",
          "Olives",
          "Peas",
          "Winter Squash"
        ]
      },
      {
        title: "Neutral", words: [
          "Wax Beans",
          "Bitter Melon", "Capsicum", "Choko",
          "Courgette", "Fuzzy Melon", "Indian Marrow", "Plantain",
          "Choi Sum", "Gai Lan", "Scallopini", "Tindora",
          "Tomatillo🐎",
          "Turia",
          "Fennel",
          "Shallot",
          "Artichoke",
          "Tat Soi",
          "Tung Ho", "Celeriac", "Daikon", "Swede Turnip",
          "Earth Gem", "Jerusalem Artichoke", "Kumara",
          "Acorn Squash", "Butternut Squash", "Hubbard Squash",
          "Tomato Juice", "Yam", "Bok Choy",
          "Mesculin", "Mustard Greens", "Romaine Lettuce",
          "Navy Beans", "Soybeans", "Split Peas",
          "White Beans", "Cassava",
          "Green Bananas", "Green Lima", "Plantains", "Taro", "Water Chestnuts",
          "Nettle",
          "Arrowroot",
          "Bibb Lettuce", "Burdock", "Capers",
          "Chard (Red)", "Chinese Cabbage", "Dandelion Greens",
          "Escarole", "Fennel Bulb", "Horseradish",
          "Bamboo Shoots", "Endive", "Parsley", "Lotus Root",
          "Pumpkin",
          "Scallions", "Soy Products", "Spaghetti Squash", "Summer Squash",
          "Swiss Chard", "Tofu", "Water Chestnut",
          "Kelp", "Mung Beans", "Tempeh", "Chives🐾🐎"
        ],
      }],

    "Fruits": [
      {
        "title": "Alkaline",
        "words": [
          "Blackberries",
          "Red Raspberries",
          "Black Raspberries",
          "Strawberries",
          "Grapes🐾🐎",
          "Gooseberries",
          "Boysenberries",
          "Cherries🐾🐎",
          "Apricots🐾🐎",
          "Nectarines",
          "Peaches",
          "Apple (Seeds) 🐾🐎",
          "Pears",
          "Oranges",
          "Tangerines",
          "Grapefruits",
          "Kumquats",
          "Lemons",
          "Limes",
          "Cantaloupe",
          "Honeydew",
          "Watermelon",
          "Avocados🐾🐎",
          "Coconut",
          "Bananas",
          "Figs",
          "Dates",
          "Guavas",
          "Mangoes",
          "Papayas",
          "Pineapples",
          "Kiwi",
          "Raisins🐾🐎",
          "Apple Pears",
          "Elderberries",
          "Loga Berries",
          "Rhubarb🐾🐎",
          "Melons - All Varieties",
          "Currants🐾🐎",
          "Muskmelon"
        ]
      },
      {
        "title": "Acidic",
        "words": [
          "Cranberries",
          "Blueberries",
          "Plums",
          "Pomegranates",
          "Currants",
          "Prune"

        ]
      },
      {
        title: "Neutral", words: [
          "Casaba",
          "Olives",
          "Avocado🐾🐎",
          "Durien",
          "Luchees",
          "Mulberries",
          "Persimmons🐾🐎",
          "Plantains",
          "Pomelas",
          "Prickly Pears",
          "Star Fruit",
        ],
      }],

    "Grains": [
      {
        "title": "Acidic",
        "words": [
          "Amaranth", "Barley", "Brown Rice🐎", "Wild Rice🐎", "White Rice🐎",
          "Oats",
          "Oatmeal", "Corn🐎", "Quinoa", "Rye",
          "Legumes", "Wheat", "Rolled Oats",
          "Whole Grain Barley", "Whole Grain Cornmeal", "Whole Rye", "Whole Wheat Crackers",
          "Whole Wheat Pasta", "Whole Wheat Bread", "Whole Wheat Tortillas",
          "Cereals",
          "Pasta", "Crackers", "Kamut", "Spelt", "Basmati Rice🐎",
          "Oat Bran", "Wheat Bran", "Bread🐎", "Macaroni", "Rice Cakes",
          "Spaghetti", "Yeast Dough🐾"
        ]
      },
      {
        title: "Neutral", words: [
          "Buckwheat", "Bulgur (Cracked Wheat)", "Flaxseed", "Millet",
          "Faro",
          "Sorghum-(Milo)", "Popcorn",
          "Triticale",
          "Muesli", "Tortilla", "Chips", "Couscous",
          "Alfalfa", "Clover",

        ],
      }],

    "Dairy": [
      {
        "title": "Milk(Alkaline)",
        "words": [
          "Low Fat", "Whole", "Flavored", "Lactose Free",
          "Lactose Reduced", "Soy Milk", "Almond Milk", "Rice Milk", "Buttermilk"
        ]
      },
      {
        "title": "Cream(Alkaline)",
        "words": ["Acidified🐾🐎", "Coffee🐾🐎", "Light🐾🐎", "Table🐾🐎", "Whipping🐾🐎"],
      },
      {
        "title": "Cottage Cheeses(Alkaline)",
        "words": [
          "Fat Free", "Low Fat", "Skim", "2%"
        ],
      },
      {
        "title": "Cheeses(Acidic)",
        "words": [
          "American",
          "Asiago",
          "Cheddar",
          "Goat",
          "Hard Cheeses",
          "Limburger",
          "Monterey Jack",
          "Mozzarella",
          "Muenster",
          "Neufchatel",
          "Parmesan",
          "Pepper Jack",
          "Provolone",
          "Ricotta",
          "Romano",
          "Soft Cheeses",
          "Swiss"
        ]
      },
      {
        "title": "Evaporated (Sweetened & Unsweetened)(Acidic)",
        "words": [
          "Eggnog🐾🐎",
          "Yogurt🐾🐎",
          "Ice Cream🐾🐎",
          "Ice Milk🐾🐎",
          "Butter🐾🐎",
          "Eggs🐾🐎",
        ]
      },
      {
        "title": "Evaporated (Sweetened & Unsweetened)",
        "words": [
          "Cream🐾🐎",
          "Dry Buttermilk🐾🐎",
          "Fat Free Milk🐾🐎",
          "Frozen Yogurt🐾🐎",
          "Kefir & Fruit Kefir🐾🐎",
          "Lowfat Milk🐾🐎",
          "Margarine🐾🐎",
          "Milk🐾🐎",
          "Nonfat Dry Milk🐾🐎",
          "Sherbet🐾🐎",
          "Sour Cream🐾🐎",
          "Whipped Cream🐾🐎",
          "Whole Dry Milk🐾🐎",
        ],
      }
    ],

    "Oils & Additives": [
      {
        "title": "Oils(Acidic)",
        "words": [
          "Corn Oil",
          "Cottonseed",
          "Olive",
          "Safflower",
          "Soybean",
          "Sunflower",
          "Walnut",
          "Sesame Oil",
          "Palm Oil",
          "Palm Kernel Oil",
          "Partially Hydrogenated Oil",
          "Peanut Oil",
          "Sesame Oil",
          "Hemp Seed Oil",
          "Rice Bran Oil",
          "Soybean Oil",
          "Vegetable Oil",
          "Avocado Oil",
        ]
      },
      {
        "title": "Oils (Neutral)",
        "words": [
          "Canola",
          "Coconut",
          "Butter",
          "Milk Fat",
          "Beef Fat",
          "Tallow Salt Margarine",
          "Shortening",
          "Ghee",
          "Nut Oils",
        ],
      },
      {
        "title": "Additives(Acidic)",
        "words": [
          "Align🐾🐎",
          "Artificial Colorings🐾🐎",
          "Artificial Flavors🐾🐎",
          "Natural Flavors🐾🐎",
          "Aspartame🐾🐎",
          "BHT🐾🐎",
          "BHA🐾🐎",
          "Calcium Carbonate🐾🐎",
          "Citric Acid🐾🐎",
          "Erythorbic Acid🐾🐎",
          "Folic Acid🐾🐎",
          "Fumaric Acid🐾🐎",
          "Guar Gum🐾🐎",
          "Iron🐾🐎",
          "Lecithin🐾🐎",
          "Methylcellulose🐾🐎",
          "Monodiglycerides🐾🐎",
          "MSG🐾🐎",
          "Pectin🐾🐎",
          "Phosphoric Acid🐾🐎",
          "Potassium Besulfate🐾🐎",
          "Sodium Benzoate🐾🐎",
          "Sodium Nitrate🐾🐎",
          "Trans Fat🐾🐎",
          "Aluminum🐾🐎",
          "Animal/Poultry Fat🐾🐎",
          "Ethoxyquin🐾🐎",
          "Corn Meal🐾🐎",
          "Meat Byproducts🐾🐎",
          "Molasses🐾🐎",
          "Peanut Hulls🐾🐎",
          "Pesticides🐾🐎",
          "Propylene Glycol🐾🐎",
          "Salt🐾🐎",
          "Soybean🐾🐎",
          "Sugar/Sweetners🐾🐎"
        ],
      }
    ],

    "Fats & Essential Fatty Acids": [
      {
        "title": "Fats",
        "words": ["Lipids",
          "Monosaturated Fat",
          "Polyunsaturated Fat",
          "Saturated Fat",
          "Hydrogenated Fat",
          "Trans Fat",
          "Canola Oil",
          "Almonds",
          "Avocado🐾🐎",
          "Olive Oil",
          "Cashews",
          "Peanuts",
          "Safflower Oil",
          "Pistachio",
          "Soybean Oil",
          "Salmon",
          "Trout",
          "Walnuts",
          "Sunflower Oil",
          "Butter",
          "Cream",
          "Ghee",
          "Mayonnaise",
          "Peanut Butter",
          "Milk",
          "Margarine",
          "Cream Cheese",
          "Cheese",
          "Eggs",
          "Ice Cream",
          "Fried Foods",
        ],
      },
      {
        "title": "Essential Fatty Acids",
        "words": [
          "Omega-3",
          "Omega-6",
          "Fish Oil",
          "Hemp",
          "Flaxseed Oil",
          "Flax",
          "Walnuts",
          "Almonds",
          "Dark Leafy Greens",
          "Broccoli",
          "Spinach",
          "Olive Oil",
          "Eggs",
          "Coconut Oil",
          "Seeds",
          "Barley",
          "Wheat",
          "Sunflower",
          "Safflower",
          "Soybeans",
          "Grains"
        ],
      }
    ],

    "Sweeteners": [
      {
        "title": "Alkaline",
        "words": [
          "Honey",
          "Barley Malt Syrup",
          "Maple Syrup",
          "Molasses",
          "Amasake",
          "Brown Rice Syrup"
        ]
      },
      {
        "title": "Acidic",
        "words": [
          "Brown Sugar",
        ]
      },
      {
        title: "Neutral", words: [
          "Sugar🐾🐎",
          "Stevia",
          "Xylitol🐾🐎",
          "Aspartame🐾🐎",
          "Sucralose🐾🐎",
          "Sucrose🐾🐎",
          "Neotame🐾🐎",
          "Saccharin🐾🐎",
          "Acesulfame Potassium🐾🐎",
          "Agave Nectar",
          "High Fructose Corn Syrup🐾🐎",
          "Sugar Alcohols🐾🐎",
          "Brazzein🐾🐎",
          "Curculin🐾🐎",
          "Glycyrrhizin🐾🐎",
          "Luo Han Guo",
          "Miraculin",
          "Monelin",
          "Pentadin",
          "Stevioside",
          "Thaumatin",
          "Cane Juice",
          "Coconut Palm Sugar",
          "Palm Sugar",
          "Sorghum Syrup",
          "Yacon Syrup",
          "Carob🐾🐎",
          "Chocolate (Unsweetened)🐾🐎",
          "Chocolate (Sweetened)🐾🐎",
          "Corn Syrup",
          "Grape Juice Concentrate",
          "Pear Juice",
          "Raspberry Juice (Frozen)",
          "Rice Malt/Syrup",
          "Sucanat",
          "Sucanat Syrup",
          "White Sugar🐾🐎",
        ],
      }],

    "Spices & Cooking": [
      {
        "title": "Spices(Acidic)",
        "words": [
          "Fenugreek",
          "Anise",
          "Caraway Seeds",
          "Fennel",
          "Salt🐾🐎",
          "Basil",
          "Bay Leaves",
          "Savory",
          "Cinnamon",
          "Nutmeg",
          "Tarragon",
          "Saffron",
          "Dill Weed",
          "Coriander",
          "Nutmeg",
          "Cloves",
          "Rosemary",
          "Thyme",
          "Parsley",
          "Salvia"
        ]
      },
      {
        "title": "Spices(Alkaline)",
        "words": [
          "Vanilla",
        ]
      },
      {
        "title": "Spices (Neutral)",
        "words": [
          "Ginseng",
          "Cardamon",
          "Chives🐾🐎",
          "Cilantro",
          "Curry",
          "Pepper (Black)",
          "Red Pepper🐎",
          "Cayenne",
          "Chili Powder",
          "All Spice",
          "Marjoram",
          "Mint",
          "Mustard",
          "Cumin",
          "Garlic🐾🐎",
          "Ginger",
          "Herbs",
          "Oregano",
          "Poppy Seeds",
          "Mace",
          "Oregano",
          "Paprika",
          "Turmeric",
          "Black Peppercorns",
          "Cream Of Tartar",
          "Kosher Salt",
          "Paprika",
          "Sesame Seeds",
          "Carob",
          "Chili Peppers",
          "Cloves",
          "Coriander",
          "Cornstarch",
          "Cress",
          "Gelatin (Plain)",
          "Horseradish",
          "Miso",
          "White Pepper",
          "Peppers (Sweet)",
          "Pickles (Cucumber)",
          "Pimento",
          "Poultry Seasoning",
          "Pumpkin Pie Spice",
          "Red Pepper Flakes🐎",
          "Sage",
          "Soy Sauce",
          "Tamari",
          "Turmeric",
          "Vanilla",
          "Agar",
          "Almond Extract",
          "Arrow Root",
          "Baking Yeast",
          "Baking Soda",
          "Braggs Liquid Aminos",
          "Capers",
          "Apple Cider Vinegar",
          "Vinegar (Red/White Wine)",
          "Vinegar Rice",
          "Vinegar White",
          "Wintergreen",
          "Worcestershire Sauce",
          "Yeast (Baking)",
          "Balsamic Vinegar",
          "Brewers Yeast",
          "Bergamot",
          "Citrus Oil Extract🐾🐎",
          "Onion Powder🐾🐎",
          "Tamarind",
        ],
      },
      {
        "title": "Cooking",
        "words": [
          "Boil",
          "Bake",
          "Fried",
          "Broiled",
          "Stir Fry",
          "Steamed",
          "Microwaved",
          "Convection Oven",
          "Toaster/Toaster Oven",
          "Grilled",
          "Raw",
          "Seared",
          "Parboiled"
        ],
      }
    ],

    "Beverages & Condiments": [
      {
        "title": "Beverages(Alkaline)",
        "words": [
          "Herbal Tea",
          "Green Tea",
          "Smoothie",
          "Cider",
          "Kombucha",
          "Mother's Milk",
          "Vegetable Juices"
        ]
      },
      {
        "title": "Beverages(Acidic)",
        "words": [
          "Regular Coffee",
          "Decaf Coffee",
          "Iced Coffee",
          "Cappuccino",
          "Latte",
          "Espresso",
          "Diet Soda",
          "Regular Soda",
          "Club Soda",
          "Fruit Juices",
          "Almond Milk",
          "Rice Milk",
          "Soy Milk",
          "Beer🐾🐎",
          "Liquor🐾🐎",
          "Sports Drinks",
          "Champagne🐾🐎",
          "Brandy Fruit Coolers🐾🐎",
          "Gin🐾🐎",
          "Rum🐾🐎",
          "Whiskey🐾🐎",
          "Vodka🐾🐎",
          "Cocoa🐾🐎",
        ]
      },
      {
        "title": "Beverages (Neutral)",
        "words": [
          "Tap Water",
          "Filtered Water",
          "Well Water",
          "Distilled Water",
          "Black Tea",
          "White Tea",
          "Iced Tea",
          "Chai Tea",
          "Lemonade",
          "Kool-Aid",
          "Juicing",
          "Cow Milk",
          "Goat Milk",
          "Energy Drinks",
          "Miwadi",
          "Wine",
          "Scotch🐾🐎",
          "Sake",
          "Seltzer Water",
        ]
      },
      {
        "title": "Condiments(Acidic)",
        "words": [
          "Ketchup",
          "Mustard",
          "Vinegar",
          "Olives",
          "Salt🐾",
          "Pepper",
          "Pepper Jelly",
          "Tahini",
          "Raspberry Jam"

        ]
      },
      {
        "title": "Condiments(Alkaline)",
        "words": [
          "Mayonnaise",
        ]
      },
      {
        "title": "Condiments (Neutral)",
        "words": [
          "Relish",
          "Steak Sauce",
          "Miracle Whip",
          "Vegemite",
          "Pickles",
          "Ranch Dressing",
          "Dressing",
          "BBQ Sauce",
          "Soy Sauce",
          "Hot Sauce",
          "Sour Cream",
          "Oil",
          "Sweet And Sour",
          "Teriyaki",
          "Worcestershire Sauce",
          "Sauerkraut",
          "Onion🐾🐎",
          "Tomato",
          "Lettuce",
          "Salsa",
          "Horseradish",
          "Tabasco",
          "Honey Mustard",
          "Tartar Sauce",
          "Chutney",
          "Butter",
          "Wasabi",
          "Harissa",
          "Fish Sauce",
          "Aioli",
          "Hoisin Sauce",
          "Sriracha",
        ],
      }
    ],

    "Vitamins & Minerals": [
      {
        "title": "Vitamins",
        "words": [
          "Alpha lipoic acid (ALA)",
          "Vitamin A",
          "Vitamin B",
          "Vitamin B1",
          "Vitamin B2",
          "Vitamin B3",
          "Vitamin B5",
          "Vitamin B6",
          "Vitamin B12",
          "Vitamin C",
          "Vitamin D",
          "Vitamin D3",
          "Vitamin E",
          "Vitamin F",
          "Vitamin K",
          "Vitamin K2",
          "Iodine",
          "Folate",

        ],
      },
      {
        "title": "Minerals",

        "words": [
          "Acidophilus",
          "Alfalfa",
          "Algae",
          "Bee Pollen",
          "Black Currant",
          "Blue-Green Algae",
          "Borage",
          "Bovine Cartilage",
          "Boron",
          "Cereal Grass",
          "Chlorella",
          "Cobalt",
          "Coenzyme Minerals",
          "CoQ10",
          "Evening Primrose Oil",
          "Flax",
          "Fluoride",
          "Garlic🐾🐎",
          "Glucosamine",
          "Greens",
          "Hops",
          "Kelp",
          "Lechithin",
          "Copper",
          "Calcium",
          "Sulfur",
          "Chromium",
          "Germanium",
          "Molybdenum",
          "Phosphorus",
          "Magnesium",
          "Manganese",
          "Potassium",
          "Vanadium",
          "Selenium",
          "Sodium",
          "Lithium",
          "Cesium",
          "Cerium",
          "Iodine",
          "Silver",
          "Silica",
          "Zinc",
          "Iron",
          "Tin",
          "Nickel",
          "Cobalt",
          "Barium",
          "Gallium",
          "Bromine",
          "Chlorine",
          "Fluorine",
          "Gold",
          "Beryillium",
          "Hydrogen",
          "Nitrogen",
          "Strontium",
          "Titanium",
          "Zirconium",
          "Trace Minerals",
          "Wicks Equine Cornerstone Minerals",
          "Carbon 13",
          "Helium 68",
          "Indium 125",
          "Iridium 125",
          "Kaons",
          "Technetium",
          "Rhodium 102",
          "Promethium 147",
          "Platinum 175",
          "Palladium 141",
          "Niobium 64"
        ],
      }
    ],
    "Herbs": [
      {
        "title": "Herbs (Alkaline)",
        "words": [
          "Alfalfa Herb",
          "Aloe Vera",
          "Angelica Root",
          "Arnica Flower",
          "Aspen Bark",
          "Astragalus Root",
          "Baptisia Root",
          "Barberry Root",
          "Bayberry Root Bark",
          "Bear Berry",
          "Benzoin Gum",
          "Betony Flower",
          "Black Cohosh Root",
          "Black Haw Root",
          "Black Walnut Hull",
          "Blessed Thistle Herb",
          "Bloodroot",
          "Blue Flag",
          "Blue Cohosh",
          "Blue Vervain Herb",
          "Blueberry Leaf",
          "Boneset",
          "Borage",
          "Boswella",
          "Brickells Herb",
          "Buchu Leaf",
          "Bugleweed Herb",
          "Burdock Root",
          "Burdock Seed",
          "Calendula Flower",
          "Cascara Sagrada Root",
          "Catnip Herb",
          "Cats Claw",
          "Cayenne Fruit",
          "Celandine, Greater Herb",
          "Chamomile Flower",
          "Chaparral Leaf",
          "Chastetree Berry",
          "Cinnamon Bark",
          "Clematis Herb",
          "Cleavers Herb",
          "Comfrey Leaf",
          "Corn Silk",
          "Cotton Root Bark",
          "Cole Parsnip Root",
          "Couch Grass",
          "Cow Parsnip Root",
          "Cramp Bark",
          "Damiana Herb",
          "Dandelion Root",
          "Desert Willow Bark",
          "Devils Claw Root",
          "Dill",
          "Dogbane Root",
          "Dong Quai Root",
          "Echinacea Root",
          "Elecampane Root",
          "Elder Flower",
          "Elderberry",
          "Eucalyptus Leaf",
          "Eyebright Herb",
          "False Unicorn Root",
          "Fennel Seed",
          "Feverfew Flower",
          "Fireweed Herb",
          "Fringetree Bark",
          "Garlic Bulb",
          "Gentian Root",
          "Ginger",
          "Gingko Leaf",
          "Goldenseal Root",
          "Gotu Kola Herb",
          "Gravel Root",
          "Grindelia Flower",
          "Guarana Seed",
          "Hawthorne Flower",
          "Hops Strobules",
          "Horehound Herb",
          "Horse Chestnut Bark",
          "Horseradish Root",
          "Horsetail Herb",
          "Immortal Root",
          "Juniper Berry",
          "Kava Kava Root",
          "Kelp Frond",
          "Kola Nut",
          "Lavender Leaf",
          "Licorice Root",
          "Lily of the Valley Root",
          "Lobella Herb",
          "Ha Huang Stern",
          "Maravilla Root",
          "Marshmallow Root",
          "Matarique Root",
          "Milk Thistle Seed",
          "Menta Polep Herb",
          "Mormon Tea",
          "Motherwort Herb",
          "Mullein Leaf",
          "Myrrh Gum",
          "Neem",
          "Nettles Herb",
          "Oat Seed",
          "Ocotillo Stern Bark",
          "Oregon Grape Root",
          "Osha Root",
          "Pansy Herb",
          "Parsley",
          "Partridge Berry",
          "Passion Flower",
          "Pau d'Arco Bark",
          "Pennyroyal Herb",
          "Peppermint",
          "Pipsissewa Herb",
          "Pleurisy Root",
          "Poke Root",
          "Prickly Ash Bark",
          "Prickly Poppy",
          "Propolis Resin",
          "Pulsatilla Flower",
          "Quassia Wood",
          "Raspberry Leaf",
          "Red Clover Flower",
          "Red Root",
          "Rosemary Leaf",
          "Sarsaparilla Root",
          "Saw Palmetto Fruit",
          "Shepherds Purse Herb",
          "Skullcap Herb",
          "Slippery Elm",
          "Southern Wood",
          "Spikenard Root",
          "Stillingia Root",
          "Storksbill Herb",
          "St. John's Wort Flower",
          "Thuja Leaf",
          "Toadflax Herb",
          "Usnea Lichen",
          "Uva Ursi Leaf",
          "Valerian Root",
          "Virginia Snake Root",
          "White Oak Bark",
          "White Sage Leaf",
          "White Willow Bark",
          "Wild Cherry Bark",
          "Wild Ginger Bark",
          "Wild Yam Root",
          "Witch Hazel Leaf",
          "Yarrow Flower",
          "Yellow Dock Root",
          "Yerba Santa Leaf",
          "Yew",
          "Yohimbe Bark",
          "Yucca Root"
        ],
      },
    ],

    "Amino Acids & Supplements": [
      {
        "title": "Amino Acids",
        "words": [
          "Alanine",
          "Arginine",
          "Asparagine",
          "Aspartic Acid",
          "Biotin",
          "Cysteine",
          "Glutamine",
          "Glycine",
          "Histidine",
          "Isoleucine",
          "Leucine",
          "Lysine",
          "Methionine",
          "Phenylalanine",
          "Proline",
          "Serine",
          "Taurine",
          "Threonine",
          "Tryptophan",
          "Tyrosine",
          "Valine"
        ]
      },
      {
        "title": "Supplements",
        "words": [
          "Acidophilus",
          "Calcium",
          "CoQ10",
          "Echinacea",
          "Fish Oil",
          "Gingko",
          "Glucosamine",
          "Green Tea",
          "Magnesium",
          "Melatonin",
          "Milk Thistle",
          "Multivitamin",
          "Niacin",
          "Probiotics",
          "Selenium",
          "Algae",
          "Bee Pollen",
          "Black Currant",
          "Blue-Green Algae",
          "Bovine Cartilage",
          "Braggs Apple Cider Vinegar",
          "Chlorella",
          "Diatomaceaous Earth",
          "Evening Primrose Oil"
        ]
      }
    ],


    "Self Care": [
      "Meditation",
      "Tai Chi",
      "Yoga",
      "Rest",
      "Play",
      "Laugh",
      "Connect With Others",
      "Warm Bath",
      "Massage",
      "Chiropractic",
      "Acupuncture",
      "Pressure Point Treatment",
      "Energy Work",
      "Create/Be Creative",
      "Music",
      "Dance",
      "Walk",
      "Sing/Chant",
      "Read For Fun",
      "Make Changes",
      "Eat Healthy/Change Diet",
      "Exercise",
      "Learn And Grow",
      "Take Healthy Risks",
      "Express Yourself",
      "Pursue Interest/Passions",
      "Change Thoughts",
      "Cut Painful Connections",
      "Change Environment",
      "Do A Clearing",
      "Seek Professional Help",
      "Spend Time In Nature",
      "Dance",
      "Enjoy Uplifting Music",
      "Engage In A Hobby",
      "Love Yourself",
      "Spend Time With An Animal",
      "Express Your Feelings",
      "Detoxify – Emotionally/Physically",
      "Take A Vacation",
      "Dream",
      "Be Creative",
      "Create A Vision Board",
      "Write Down Your Intentions",
      "Volunteer",
      "Help Someone Else Out",
      "Ask For Help",
      "Receive",
      "Give",
      "Something Not Listed",
      "Get More Sleep"
    ],

    "Medication": [
      "Allergy Meds🐾🐎",
      "Aspirin🐾🐎",
      "Ibuprophen🐾🐎",
      "Inhalers🐾🐎",
      "Steroids🐾🐎",
      "Bronchodilators🐾🐎",
      "Anti-Anxiety🐾🐎",
      "Anti-Depressant🐾🐎",
      "Cholesterol Meds🐾🐎",
      "Cumadin🐾🐎",
      "Bone Density Meds🐾🐎",
      "Hormones🐾🐎",
      "Insulin🐾🐎",
      "Seizure Meds🐾🐎",
      "Anti-Inflammatory🐾🐎",
      "Acetaminophen🐾🐎",
      "Duloxetine🐾🐎",
      "Naproxen Sodium🐾🐎",
      "Diciofenac-Misoprostol🐾🐎",
      "Celecoxib🐾🐎",
      "Piroxicam🐾🐎",
      "Indomethacin🐾🐎",
      "Meloxicam🐾🐎",
      "Ketoprofen🐾🐎",
      "Sulindac🐾🐎",
      "Tramadol🐾🐎",
      "Prednisone🐾🐎",
      "Dexamethasone🐾🐎",
      "Hydrocortisone🐾🐎",
      "Methylprednisone🐾🐎",
      "Hydrocodine🐾🐎",
      "Codeine🐾🐎",
      "Capsaicin🐾🐎",
      "Trolamine🐾🐎",
      "Ritalin🐾🐎",
      "Sleep Aids🐾🐎",
      "Gastrointestinal🐾🐎",
      "Cough/Cold/Allergy🐾🐎",
      "Analgesic🐾🐎",
      "Oral Health Products🐾🐎",
      "Antacids🐾🐎",
      "Laxatives🐾🐎",
    ],


    "Emotions": {
      "Scared": [
        "Afraid",
        "Alarmed",
        "Anxious",
        "Appalled",
        "Apprehensive",
        "Awed",
        "Concerned",
        "Defensive",
        "Desperate",
        "Doubtful",
        "Fearful",
        "Frantic",
        "Full of dread",
        "Guarded",
        "Horrified",
        "Impatient",
        "Insecure",
        "Intimidated",
        "Nervous",
        "Overwhelmed",
        "Panicky",
        "Perplexed",
        "Petrified",
        "Reluctant",
        "Shaken",
        "Shocked",
        "Shy",
        "Skeptical",
        "Startled",
        "Stunned",
        "Suspicious",
        "Swamped",
        "Tense",
        "Terrified",
        "Threatened",
        "Timid",
        "Tormented",
        "Uneasy",
        "Unsure",
        "Vulnerable"
      ],
      "Confused": [
        "Ambivalent",
        "Awkward",
        "Baffled",
        "Bewildered",
        "Bothered",
        "Constricted",
        "Directionless",
        "Disorganized",
        "Distracted",
        "Doubtful",
        "Flustered",
        "Foggy",
        "Hesitant",
        "Immobilized",
        "Misunderstood",
        "Perplexed",
        "Puzzled",
        "Stagnant",
        "Surprised",
        "Torn",
        "Trapped",
        "Troubled",
        "Uncertain",
        "Uncomfortable",
        "Undecided",
        "Unsettled",
        "Unsure"
      ],
      "Happy": [
        "Admired",
        "Alive",
        "Appreciated",
        "Assured",
        "Cheerful",
        "Confident",
        "Content",
        "Delighted",
        "Determined",
        "Ecstatic",
        "Elated",
        "Encouraged",
        "Energized",
        "Enthusiastic",
        "Excited",
        "Exuberant",
        "Flattered",
        "Fortunate",
        "Fulfilled",
        "Glad",
        "Good",
        "Grateful",
        "Gratified",
        "Hopeful",
        "Joyful",
        "Jubilant",
        "Justified",
        "Loved",
        "Marvelous",
        "Optimistic",
        "Peaceful",
        "Pleased",
        "Proud",
        "Relaxed",
        "Relieved",
        "Resolved",
        "Respected",
        "Satisfied",
        "Terrific",
        "Thrilled",
        "Tranquil",
        "Valued"
      ],
      "Sad": [
        "Alienated",
        "Ashamed",
        "Burdened",
        "Condemned",
        "Crushed",
        "Defeated",
        "Dejected",
        "Demoralized",
        "Depressed",
        "Deserted",
        "Despised",
        "Devastated",
        "Disappointed",
        "Discarded",
        "Discouraged",
        "Disgraced",
        "Disheartened",
        "Disillusioned",
        "Dismal",
        "Distant",
        "Distraught",
        "Distressed",
        "Drained",
        "Empty",
        "Exhausted",
        "Grievous",
        "Helpless",
        "Hopeless",
        "Humbled",
        "Inadequate",
        "Islanded",
        "Isolated",
        "Lonely",
        "Miserable",
        "Mournful",
        "Neglected",
        "Pitiful",
        "Regretful",
        "Rejected",
        "Resigned",
        "Sorrowful",
        "Terrible",
        "Unappreciated",
        "Uncared for",
        "Unloved",
        "Unwanted",
        "Upset",
        "Worthless",
        "Wounded"
      ],
      "Angry": [
        "Abused",
        "Aggravated",
        "Agitated",
        "Anguished",
        "Annoyed",
        "Betrayed",
        "Cheated",
        "Coerced",
        "Controlled",
        "Deceived",
        "Disgusted",
        "Dismayed",
        "Displeased",
        "Dominated",
        "Enraged",
        "Exasperated",
        "Exploited",
        "Frustrated",
        "Fuming",
        "Furious",
        "Harassed",
        "Hateful",
        "Hostile",
        "Humiliated",
        "Incensed",
        "Irritated",
        "Mad",
        "Offended",
        "Outraged",
        "Patronized",
        "Peeved",
        "Perturbed",
        "Pissed Off",
        "Provoked",
        "Rebellious",
        "Repulsed",
        "Resentful",
        "Ridiculed",
        "Sabotaged",
        "Seething",
        "Smothered",
        "Spiteful",
        "Stifled",
        "Strangled",
        "Throttled",
        "Uptight",
        "Used",
        "Vengeful",
        "Vindictive"
      ]
    },

  }
}