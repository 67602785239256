import { ChartPagesType } from '../types'
import { BusinessPopUps } from './popups'

export const BusinessCharts: ChartPagesType = {
  "Business": {
    chart: [
      { id: 1, label: 'Compass', value: 1, color: '#8F241F' },
      { id: 2, label: 'Room/Loc.', value: 1, color: '#D52329' },
      { id: 3, label: 'Remedies', value: 1, color: '#FB2B17' },
      { id: 4, label: 'Actions', value: 1, color: '#FF581D' },
      { id: 5, label: 'Building Issues', value: 1, color: '#FF9328' },
      { id: 6, label: 'Relation/Team', value: 1, color: '#FDB02D' },
      { id: 7, label: 'Communications', value: 1, color: '#FFE044' },
      { id: 8, label: 'Heart of the Business', value: 1, color: '#87C846' },
      { id: 9, label: 'Core Values', value: 1, color: '#4BAD43' },
      { id: 10, label: 'Quality Assurance', value: 1, color: '#005D38' },
      { id: 11, label: 'Strategy', value: 1, color: '#00656E' },
      { id: 12, label: 'Products & Services', value: 1, color: '#0086AE' },
      { id: 14, label: 'Chakras', value: 1, color: '#0D2D94' },
      { id: 15, label: 'Color Chart', value: 1, color: '#0F1D6D' },
      { id: 16, label: 'Bagua Areas', value: 1, color: '#502795' },
      { id: 17, label: 'Unwanted Visitors', value: 1, color: '#7F2479' },
      { id: 19, label: 'Self-Love', value: 1, color: '#54544D' },
    ],

    parent: "All Modules",
    level: 1,
    subPages: {

      "Compass": {
        chart: [
          { id: 0, label: 'West', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.Compass.West },
          { id: 1, label: 'North', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.Compass.North },
          { id: 2, label: 'East', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.Compass.East},
          { id: 3, label: 'South', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.Compass.South},

        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },

      "Room/Loc.": {
        chart: [
          { id: 0, label: 'Showroom', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 1, label: 'Checkout Area', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 2, label: 'Locker Room', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 3, label: 'Studio', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 4, label: 'Classroom', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 5, label: 'Supply Room', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 6, label: 'Lounge', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 7, label: 'Bar', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 8, label: 'Kitchen', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 9, label: 'Train. Room', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.TrainingRoom },
          { id: 10, label: 'Wait. Area', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.WaitingArea },
          { id: 11, label: 'Your Station', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 12, label: 'Cubicals', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 13, label: 'Recpt. Area', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.ReceptionArea },
          { id: 14, label: 'Meet. Room', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.MeetingRoom },
          { id: 15, label: 'Bathroom', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 16, label: 'Conf. Room', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.ConferenceRoom },
          { id: 17, label: 'Parking/Lot', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 18, label: 'Storage Room', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 19, label: 'Breakroom', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 20, label: 'Boardroom', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 21, label: 'Dining Room', value: 1, color: '#F0C3CB', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
          { id: 22, label: 'Whole Building/Area', value: 1, color: '#C3102F', popupContent: BusinessPopUps.Business.RoomLocation.NoInfo },
        ],
        parent: "Business",
        level: 2,
        subPages: {

        },
      },


      "Remedies": {
        chart: [
          { id: 0, label: 'Water Feature', value: 1, color: '#E51A23', popupContent: BusinessPopUps.Business.Remedies.Water },
          { id: 1, label: 'Personal Expression', value: 1, color: '#F8C6C8', popupContent: BusinessPopUps.Business.Remedies.NoInfo },
          { id: 2, label: 'Call in Angels', value: 1, color: '#E51A23', popupContent: BusinessPopUps.Business.Remedies.NoInfo },
          { id: 3, label: 'Animal/s', value: 1, color: '#F8C6C8', popupContent: BusinessPopUps.Business.Remedies.Animals },
          { id: 4, label: 'Feng Shui', value: 1, color: '#E51A23', popupContent: BusinessPopUps.Business.Remedies.FengShui },
          { id: 5, label: 'Plants', value: 1, color: '#F8C6C8', popupContent: BusinessPopUps.Business.Remedies.Plants },
          { id: 6, label: 'Crystals', value: 1, color: '#E51A23' },
          { id: 7, label: 'Oils', value: 1, color: '#F8C6C8' },
          { id: 8, label: 'Lighting', value: 1, color: '#E51A23', popupContent: BusinessPopUps.Business.Remedies.NoInfo },
          { id: 9, label: 'Sage', value: 1, color: '#F8C6C8', popupContent: BusinessPopUps.Business.Remedies.NoInfo },
          { id: 10, label: 'Windows', value: 1, color: '#E51A23', popupContent: BusinessPopUps.Business.Remedies.Windows },
          { id: 11, label: 'Music', value: 1, color: '#F8C6C8', popupContent: BusinessPopUps.Business.Remedies.NoInfo },
          { id: 12, label: 'Take out trash', value: 1, color: '#E51A23', popupContent: BusinessPopUps.Business.Remedies.NoInfo },
          { id: 13, label: 'Dust', value: 1, color: '#F8C6C8', popupContent: BusinessPopUps.Business.Remedies.NoInfo },
          { id: 14, label: 'Art', value: 1, color: '#E51A23', popupContent: BusinessPopUps.Business.Remedies.Art },
          { id: 15, label: 'Window Coverings', value: 1, color: '#F8C6C8', popupContent: BusinessPopUps.Business.Remedies.NoInfo },
          { id: 16, label: 'Animal Companion', value: 1, color: '#E51A23', popupContent: BusinessPopUps.Business.Remedies.AnimalCompanion },

        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },

      "Actions": {
        chart: [

          { id: 0, label: 'Redecorate/Update', value: 1, color: '#F25B21', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 1, label: 'Physical Items', value: 1, color: '#FBD6C8', popupContent: BusinessPopUps.Business.Actions.Item },
          { id: 2, label: 'Foundation Issues', value: 1, color: '#F25B21', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 3, label: 'Paint/New Color', value: 1, color: '#FBD6C8', popupContent: BusinessPopUps.Business.Actions.Paint },
          { id: 4, label: 'Clean', value: 1, color: '#F25B21', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 5, label: 'Mold Removal', value: 1, color: '#FBD6C8', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 6, label: 'Organize', value: 1, color: '#F25B21', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 7, label: 'Remodel', value: 1, color: '#FBD6C8', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 8, label: 'Music', value: 1, color: '#F25B21', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 9, label: 'Climate Change', value: 1, color: '#FBD6C8', popupContent: BusinessPopUps.Business.Actions.Climate },
          { id: 10, label: 'Landscape', value: 1, color: '#F25B21', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 11, label: 'Lighting', value: 1, color: '#FBD6C8', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 12, label: 'Declutter', value: 1, color: '#F25B21', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 13, label: 'Recheck Frequency', value: 1, color: '#FBD6C8', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 14, label: 'Asbestos Removal', value: 1, color: '#F25B21', popupContent: BusinessPopUps.Business.Actions.NoInfo },
          { id: 15, label: 'Intention & Goals', value: 1, color: '#FBD6C8', popupContent: BusinessPopUps.Business.Actions.Intention },
        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },

      "Building Issues": {
        chart: [
          { id: 0, label: 'Entrances/Exits', value: 1, color: '#F7971D', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 1, label: 'Sewer/Well/Septic', value: 1, color: '#FDE5C7', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 2, label: 'Insulation', value: 1, color: '#F7971D', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 3, label: 'Exterior', value: 1, color: '#FDE5C7', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 4, label: 'Fireplace', value: 1, color: '#F7971D', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 5, label: 'Heating/Cooling', value: 1, color: '#FDE5C7', popupContent: BusinessPopUps.Business.BuildingIssues.HeatCool },
          { id: 6, label: 'Morale', value: 1, color: '#F7971D', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 7, label: 'Relationships', value: 1, color: '#FDE5C7', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 8, label: 'Electrical', value: 1, color: '#F7971D', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 9, label: 'Plumbing', value: 1, color: '#FDE5C7', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 10, label: 'Roof', value: 1, color: '#F7971D', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 11, label: 'Lighting', value: 1, color: '#FDE5C7', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 12, label: 'Ceiling', value: 1, color: '#F7971D', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 13, label: 'Doors', value: 1, color: '#FDE5C7', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 14, label: 'Floors', value: 1, color: '#F7971D', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 15, label: 'Walls', value: 1, color: '#FDE5C7', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 16, label: 'Windows', value: 1, color: '#F7971D', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 17, label: 'Appliances/Tools', value: 1, color: '#FDE5C7', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 18, label: 'Office Configuration', value: 1, color: '#F7971D', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
          { id: 19, label: 'Foundation/Basement', value: 1, color: '#FDE5C7', popupContent: BusinessPopUps.Business.BuildingIssues.NoInfo },
        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },


      "Relation/Team": {
        chart: [
          { id: 0, label: 'Community Relationship', value: 1, color: '#EFB211', popupContent: BusinessPopUps.Business.Relationships.NoInfo },
          { id: 1, label: 'Vendor Relationship', value: 1, color: '#FBEBC4', popupContent: BusinessPopUps.Business.Relationships.NoInfo },
          { id: 2, label: 'Board of Directors', value: 1, color: '#EFB211', popupContent: BusinessPopUps.Business.Relationships.NoInfo },
          { id: 3, label: 'Partners', value: 1, color: '#FBEBC4', popupContent: BusinessPopUps.Business.Relationships.NoInfo },
          { id: 4, label: 'Owners', value: 1, color: '#EFB211', popupContent: BusinessPopUps.Business.Relationships.NoInfo },
          { id: 5, label: 'Self', value: 1, color: '#FBEBC4', popupContent: BusinessPopUps.Business.Relationships.Self },
          { id: 6, label: 'Management', value: 1, color: '#EFB211', popupContent: BusinessPopUps.Business.Relationships.NoInfo },
          { id: 7, label: 'Accountant', value: 1, color: '#FBEBC4', popupContent: BusinessPopUps.Business.Relationships.NoInfo },
          { id: 8, label: 'Special Events', value: 1, color: '#EFB211', popupContent: BusinessPopUps.Business.Relationships.NoInfo },
          { id: 9, label: 'Business Coach', value: 1, color: '#FBEBC4', popupContent: BusinessPopUps.Business.Relationships.NoInfo },
          { id: 10, label: 'Animal Companion', value: 1, color: '#EFB211', popupContent: BusinessPopUps.Business.Relationships.Animal },
          { id: 11, label: 'Human Resources', value: 1, color: '#FBEBC4', popupContent: BusinessPopUps.Business.Relationships.NoInfo },
          { id: 12, label: 'Customer Loyalty Program', value: 1, color: '#EFB211', popupContent: BusinessPopUps.Business.Relationships.NoInfo },

        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },

      "Communications": {
        chart: [
          { id: 0, label: 'Words of Affirmation', labelWithFormatting:"Words of\nAffirmation",value: 1, color: '#FFE616', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 1, label: 'Empowerment Strategies', labelWithFormatting:"Empowerment\nStrategies", value: 1, color: '#FFF8C5', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 2, label: 'Physical Touch', value: 1, color: '#FFE616', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 3, label: 'Acts of Service', value: 1, color: '#FFF8C5', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 4, label: 'Gifts', value: 1, color: '#FFE616', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 5, label: 'Quality Time', value: 1, color: '#FFF8C5', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 6, label: 'Approachability', value: 1, color: '#FFE616', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 7, label: 'Motivation', value: 1, color: '#FFF8C5', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 8, label: 'Problem Solving', value: 1, color: '#FFE616', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 9, label: 'Openness', value: 1, color: '#FFF8C5', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 10, label: 'Ask for help', value: 1, color: '#FFE616', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 11, label: 'Listening', value: 1, color: '#FFF8C5', popupContent: BusinessPopUps.Business.Communication.NoInfo },
          { id: 12, label: 'Face-to-face Relating', labelWithFormatting:"Face-to-face\nRelating" ,value: 1, color: '#FFE616', popupContent: BusinessPopUps.Business.Communication.NoInfo },
        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },

      "Heart of the Business": {
        chart: [

          { id: 0, label: 'Affirmations', value: 1, color: '#8BC63E', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 1, label: 'Intentions', value: 1, color: '#E2F0CF', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 2, label: 'Goals', value: 1, color: '#8BC63E', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 3, label: 'Self-talk', value: 1, color: '#E2F0CF', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 4, label: 'Beliefs', value: 1, color: '#8BC63E', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 5, label: 'Vision', value: 1, color: '#E2F0CF', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 6, label: 'Decisions', value: 1, color: '#8BC63E', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 7, label: 'Values', value: 1, color: '#E2F0CF', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 8, label: 'Honesty', value: 1, color: '#8BC63E', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 9, label: 'Boundaries', value: 1, color: '#E2F0CF', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 10, label: 'Time Off', value: 1, color: '#8BC63E', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 11, label: 'Flexibility', value: 1, color: '#E2F0CF', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 12, label: 'Feedback', value: 1, color: '#8BC63E', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 13, label: 'Humaness', value: 1, color: '#E2F0CF', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 14, label: 'Intuition', value: 1, color: '#8BC63E', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 15, label: 'Heart Centered', value: 1, color: '#E2F0CF', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 16, label: 'Authenticity', value: 1, color: '#8BC63E', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 17, label: 'Appreciation', value: 1, color: '#E2F0CF', popupContent: BusinessPopUps.Business.Heart.NoInfo },
          { id: 18, label: 'Mission Statement', value: 1, color: '#8BC63E', popupContent: BusinessPopUps.Business.Heart.NoInfo }
        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },

      "Core Values": {
        chart: [
          { id: 0, label: 'Sense of Importance', value: 1, color: '#46AA42', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 1, label: 'Authenticity', value: 1, color: '#D1EAD0', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 2, label: 'Transparency', value: 1, color: '#46AA42', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 3, label: 'Integrity', value: 1, color: '#D1EAD0', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 4, label: 'Loyalty', value: 1, color: '#46AA42', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 5, label: 'Connection', value: 1, color: '#D1EAD0', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 6, label: 'Acceptance', value: 1, color: '#46AA42', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 7, label: 'Productivity', value: 1, color: '#D1EAD0', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 8, label: 'Diversity', value: 1, color: '#46AA42', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 9, label: 'Playfulness', value: 1, color: '#D1EAD0', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 10, label: 'Creativity', value: 1, color: '#46AA42', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 11, label: 'Personal Growth', value: 1, color: '#D1EAD0', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 12, label: 'Sense of Accomplishment', labelWithFormatting:'Sense of \n Accomplishment',value: 1, color: '#46AA42', popupContent: BusinessPopUps.Business.Values.NoInfo },
          { id: 13, label: 'Wellness', value: 1, color: '#D1EAD0', popupContent: BusinessPopUps.Business.Values.Wellness },

        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },

      "Quality Assurance": {
        chart: [

          { id: 0, label: 'Employee Satisfaction', labelWithFormatting: 'Employee \n Satisfaction', value: 1, color: '#00653A', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 1, label: 'Vacation Time/Use', value: 1, color: '#C0D8CE', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 2, label: 'Benefits', value: 1, color: '#00653A', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 3, label: 'Perks', value: 1, color: '#C0D8CE', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 4, label: 'Training', value: 1, color: '#00653A', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 5, label: 'Connections', value: 1, color: '#C0D8CE', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 6, label: 'Profit Sharing', value: 1, color: '#00653A', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 7, label: 'Tools', value: 1, color: '#C0D8CE', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 8, label: 'Mentoring', value: 1, color: '#00653A', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 9, label: 'Resources', value: 1, color: '#C0D8CE', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 10, label: 'Performance Evals', value: 1, color: '#00653A', popupContent: BusinessPopUps.Business.QA.PerformanceEvals },
          { id: 11, label: 'Task/Job Compatibility', labelWithFormatting: 'Task/Job \n Compatibility',  value: 1, color: '#C0D8CE', popupContent: BusinessPopUps.Business.QA.NoInfo },
          { id: 12, label: 'Acknowledge Gift Areas',  labelWithFormatting: 'Acknowledge \n Gift Areas',value: 1, color: '#00653A', popupContent: BusinessPopUps.Business.QA.NoInfo },

        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },
      "Strategy": {
        chart: [

          { id: 0, label: 'Organizational Chart', value: 1, color: '#006B6E', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 1, label: 'Leadership Styles', value: 1, color: '#C0DADB', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 2, label: 'Website', value: 1, color: '#006B6E', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 3, label: 'Business Cards', value: 1, color: '#C0DADB', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 4, label: 'Logo', value: 1, color: '#006B6E', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 5, label: 'Networking', value: 1, color: '#C0DADB', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 6, label: 'Location', value: 1, color: '#006B6E', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 7, label: 'Company Image', value: 1, color: '#C0DADB', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 8, label: 'Cash flow', value: 1, color: '#006B6E', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 9, label: 'Advertising', value: 1, color: '#C0DADB', popupContent: BusinessPopUps.Business.Strategy.Advertising },
          { id: 10, label: 'Social Media', value: 1, color: '#006B6E', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 11, label: 'Grants/Loans', value: 1, color: '#C0DADB', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 12, label: 'Fund Raising', value: 1, color: '#006B6E', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 13, label: 'Marketing Materials', value: 1, color: '#C0DADB', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 14, label: 'Strategic Planning', value: 1, color: '#006B6E', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 15, label: 'Transparency/ Authenticity',labelWithFormatting: 'Transparency/ \n Authenticity', value: 1, color: '#C0DADB', popupContent: BusinessPopUps.Business.Strategy.NoInfo },
          { id: 16, label: 'Downsizing', value: 1, color: '#006B6E', popupContent: BusinessPopUps.Business.Strategy.NoInfo },

        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },

      "Products & Services": {
        chart: [
          { id: 0, label: 'Stream of Income', value: 1, color: '#0083A9', popupContent: BusinessPopUps.Business.ProdServ.Stream },
          { id: 1, label: 'Price Change', value: 1, color: '#C0E0E9', popupContent: BusinessPopUps.Business.ProdServ.Price },
          { id: 2, label: 'Simplify', value: 1, color: '#0083A9', popupContent: BusinessPopUps.Business.ProdServ.Price },
          { id: 3, label: 'Re-image', value: 1, color: '#C0E0E9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 4, label: 'Repackage', value: 1, color: '#0083A9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 5, label: 'Discontinue', value: 1, color: '#C0E0E9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 6, label: 'Add/Change', value: 1, color: '#0083A9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 7, label: 'Rethink', value: 1, color: '#C0E0E9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 8, label: 'Diversify', value: 1, color: '#0083A9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 9, label: 'Useability', value: 1, color: '#C0E0E9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 10, label: 'Logo-Wear/Ware', value: 1, color: '#0083A9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 11, label: 'Practicality', value: 1, color: '#C0E0E9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 12, label: 'Target Market', value: 1, color: '#0083A9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 13, label: 'Mission Statement', value: 1, color: '#C0E0E9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },
          { id: 14, label: 'Charitable Donation', value: 1, color: '#0083A9', popupContent: BusinessPopUps.Business.ProdServ.NoInfo },

        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },

      "Chakras": {
        chart: [
          { id: 0, label: 'Third Eye Chakra', value: 1, color: '#263692', popupContent: BusinessPopUps.Business.Chakras.ThirdEye },
          { id: 1, label: 'Sacral Chakra', value: 1, color: '#C9CDE4', popupContent: BusinessPopUps.Business.Chakras.Sacral },
          { id: 2, label: 'Solar Plexus', value: 1, color: '#263692', popupContent: BusinessPopUps.Business.Chakras.Solar },
          { id: 3, label: 'Heart Chakra', value: 1, color: '#C9CDE4', popupContent: BusinessPopUps.Business.Chakras.Heart },
          { id: 4, label: 'Throat Chakra', value: 1, color: '#263692', popupContent: BusinessPopUps.Business.Chakras.Throat },
          { id: 5, label: 'Root Chakra', value: 1, color: '#C9CDE4', popupContent: BusinessPopUps.Business.Chakras.Root },
          { id: 6, label: 'Crown Chakra', value: 1, color: '#263692', popupContent: BusinessPopUps.Business.Chakras.Crown },
          { id: 7, label: 'Eighth Chakra', value: 1, color: '#C9CDE4', popupContent: BusinessPopUps.Business.Chakras.Eighth },
          //removed 2 chakras
          { id: 10, label: 'Chakra Not Listed', value: 1, color: '#263692', popupContent: BusinessPopUps.Business.Chakras.NoInfo },

        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },
      "Color Chart": {
        chart: [
          { id: 0, label: 'Red', value: 1, color: '#FB2B17', popupContent: BusinessPopUps.Business.Color.Hue },
          { id: 1, label: 'Red-Orange', value: 1, color: '#FF5349', popupContent: BusinessPopUps.Business.Color.Hue },
          { id: 2, label: 'Orange', value: 1, color: '#FF581D', popupContent: BusinessPopUps.Business.Color.Hue },
          { id: 3, label: 'Yellow-Orange', value: 1, color: '#FFAE42', popupContent: BusinessPopUps.Business.Color.Hue},
          { id: 4, label: 'Yellow', value: 1, color: '#FFE044', popupContent: BusinessPopUps.Business.Color.Hue },
          { id: 5, label: 'Yellow-Green', value: 1, color: '#C9D731', popupContent: BusinessPopUps.Business.Color.Hue },
          { id: 6, label: 'Green', value: 1, color: '#0FAE4D', popupContent: BusinessPopUps.Business.Color.Hue },
          { id: 7, label: 'Blue-Green', value: 1, color: '#088F8F', popupContent: BusinessPopUps.Business.Color.Hue },
          { id: 8, label: 'Blue', value: 1, color: '#0B349B', popupContent: BusinessPopUps.Business.Color.Hue },
          { id: 9, label: 'Blue-Violet', value: 1, color: '#510AC9', popupContent: BusinessPopUps.Business.Color.Hue },
          { id: 10, label: 'Violet', value: 1, color: '#5C00A3', popupContent: BusinessPopUps.Business.Color.Hue },
          { id: 11, label: 'Red-Violet', value: 1, color: '#c71585', popupContent: BusinessPopUps.Business.Color.Hue },
        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },

      "Bagua Areas": {
        chart: [
          { id: 0, label: 'Bagua Map', value: 1, color: '#D52329'},
          { id: 1, label: 'Wealth & Prosperity', value: 1, color: '#692C91', popupContent: BusinessPopUps.Business.Bagua.NoInfo },
          { id: 2, label: 'Fame & Reputation', value: 1, color: '#F48026', popupContent: BusinessPopUps.Business.Bagua.NoInfo },
          { id: 3, label: 'Love & Relationships', value: 1, color: '#F390B0', popupContent: BusinessPopUps.Business.Bagua.Love },
          { id: 4, label: 'Family & Friends', value: 1, color: '#00653A', popupContent: BusinessPopUps.Business.Bagua.Family },
          { id: 5, label: 'Health & Well Being', value: 1, color: '#FFE616', popupContent: BusinessPopUps.Business.Bagua.Health },
          { id: 6, label: 'Children & Creativity', value: 1, color: '#f0f0f0', popupContent: BusinessPopUps.Business.Bagua.NoInfo },
          { id: 7, label: 'Knowledge & Wisdom',labelWithFormatting: 'Knowledge  & \n Wisdom', value: 1, color: '#263692', popupContent: BusinessPopUps.Business.Bagua.NoInfo },
          { id: 8, label: 'Career', value: 1, color: '#231F20', popupContent: BusinessPopUps.Business.Bagua.Career },
          { id: 9, label: 'Travel & Helpful People', labelWithFormatting: 'Travel & \n Helpful People',value: 1, color: '#BEBFC2', popupContent: BusinessPopUps.Business.Bagua.NoInfo }
        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },
      "Unwanted Visitors": {
        chart: [
          { id: 0, label: 'Wasps,Hornets,Bees', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Wasps },
          { id: 1, label: 'Bed Bugs', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Bed },
          { id: 2, label: 'Crickets/Beetles', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Crickets },
          { id: 3, label: 'Birds', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Birds },
          { id: 4, label: 'Bats', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Bats },
          { id: 5, label: 'Ants', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Ants },
          { id: 6, label: 'Fruit Flies', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Fruit },
          { id: 7, label: 'Mosquitoes', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Mosquitoes },
          { id: 8, label: 'Flies/Fleas', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.FliesFleas },
          { id: 9, label: 'Moth', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Moth },
          { id: 10, label: 'Cockroaches', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Cockroaches },
          { id: 11, label: 'Slugs', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Slugs },
          { id: 12, label: 'Centipedes', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Centipedes },
          { id: 13, label: 'Rabbits', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Rabbits },
          { id: 14, label: 'Moles/Voles', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Moles },
          { id: 15, label: 'Deer', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Deer },
          { id: 16, label: 'Squirrels', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Squirrels },
          { id: 17, label: 'Spiders', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Spiders },
          { id: 18, label: 'Snakes', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Snakes },
          { id: 19, label: 'Lizards/Reptiles', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Lizards },
          { id: 20, label: 'Skunks', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Skunks },
          { id: 21, label: 'Porcupines', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Porcupines },
          { id: 22, label: 'Mice/Rats', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.Mice },
          { id: 23, label: 'Gophers/Prairie Dogs', value: 1, color: '#E1C7DC', popupContent: BusinessPopUps.Business.Unwanted.Gophers },
          { id: 24, label: 'Animal has a deeper message', value: 1, color: '#862074', popupContent: BusinessPopUps.Business.Unwanted.DeeperMsg },

        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },


      "Self-Love": {
        chart: [

          { id: 0, label: 'Do what you love', value: 1, color: '#535249', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 1, label: 'Take a vacation', value: 1, color: '#D4D4D2', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 2, label: 'Utilize your gifts', value: 1, color: '#535249', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 3, label: 'Work Less', value: 1, color: '#D4D4D2', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 4, label: 'Intuition', value: 1, color: '#535249', popupContent: BusinessPopUps.Business.SelfLove.Intuition },
          { id: 5, label: 'Take more risks', value: 1, color: '#D4D4D2', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 6, label: 'Change Job', value: 1, color: '#535249', popupContent: BusinessPopUps.Business.SelfLove.Job },
          { id: 7, label: 'Work More', value: 1, color: '#D4D4D2', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 8, label: 'Go for it', value: 1, color: '#535249', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 9, label: 'Prof. Guidance', value: 1, color: '#D4D4D2', popupContent: BusinessPopUps.Business.SelfLove.Prof },
          { id: 10, label: 'Be more focused', value: 1, color: '#535249', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 11, label: 'Be of service', value: 1, color: '#D4D4D2', popupContent: BusinessPopUps.Business.SelfLove.Service },
          { id: 12, label: 'Brainstorm', value: 1, color: '#535249', popupContent: BusinessPopUps.Business.SelfLove.Brainstorm },
          { id: 13, label: 'Take lunch breaks', value: 1, color: '#D4D4D2', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 14, label: 'Get out of your head', value: 1, color: '#535249', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 15, label: 'Hire Expert Help', value: 1, color: '#D4D4D2', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },
          { id: 16, label: 'Something not listed', value: 1, color: '#535249', popupContent: BusinessPopUps.Business.SelfLove.NoInfo },

        ],
        parent: "Business",
        level: 2,
        subPages: {}
      },
    }
  }
}
